import React, {useContext, useEffect, useState} from 'react';
import "./UsersTable.css"
import NavigateHeader from "../../../UI/NavigateHeader/NavigateHeader";
import {ConfigProvider, Table} from "antd";
import {Context} from "../../../../index";
import AdminService from "../../../../services/AdminService";
import Button from "../../../UI/Button/Button";
import CreateNewUser from "./CreateNewUser/CreateNewUser";

interface ITableUsers {
    key: string,
    id: number,
    fio: string,
    email: string,
    phone: string,
    in_company: boolean,
    tariff: string,
    daysFree: number
}

const UsersTable = () => {
    const {store} = useContext(Context)
    const [dataSource, setDataSource] = useState<ITableUsers[]>()
    const [oldDataSource, setOldDataSource] = useState<ITableUsers[]>()
    const [createUserPopup, setCreateUserPopup] = useState<JSX.Element | null>(null)

    const getDataTable = () => {
        store.DataLoadingON()
        AdminService.getTableUsers()
            .then((r) => {
                setDataSource(r.data)
                setOldDataSource(r.data)
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    /*const dataSource = [
        {
            key: '1',
            name: 'Mike',
            age: 32,
            address: '10 Downing Street',
        },
        {
            key: '2',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },
    ];*/

    const columns = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'ФИО',
            dataIndex: 'fio',
            key: 'fio',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'В компании',
            dataIndex: 'in_company',
            key: 'in_company',
        },
        {
            title: 'Активный тариф',
            dataIndex: 'tariff',
            key: 'tariff',
        },
        {
            title: 'Свободных дней',
            dataIndex: 'daysFree',
            key: 'daysFree',
        },
    ];

    useEffect(() => {
        getDataTable()
    }, [])

    const onSearch = (search: string) => {
        if (search?.length && search?.length > 0) {
            setDataSource(oldDataSource?.filter(i => {
                if (i.fio.includes(search))
                    return i
                if (i.email.includes(search))
                    return i
                if (i.phone.includes(search))
                    return i
            }))
        } else {
            setDataSource(oldDataSource)
        }
    }

    return (
        <div className={"usersTable"}>
            <NavigateHeader size={"medium"} Text={"Пользователи"}/>
            <div className={"usersTable-options"}>
                <div className={"usersTable-search"}>
                    <img src={"/Pictures/searchIcon.svg"}/>
                    <input type={"text"} placeholder={"Поиск"} onChange={(e) => onSearch(e.target.value)}/>
                </div>
                <div className={"usersTable-addUser"}>
                    <Button onClick={() => {
                        setCreateUserPopup(<CreateNewUser onCreate={() => getDataTable()}
                                                          onClose={() => setCreateUserPopup(null)}
                        />)
                    }}
                            type={"default"} styleProps={"red"}
                    >
                        Добавить пользователя
                    </Button>
                </div>
            </div>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            borderColor: "#AA0A22",
                            headerBg: "rgba(84,84,84,0.11)"
                        },
                    },
                    token: {

                        fontFamily: "Montserrat",
                        colorPrimary: '#AA0A22',
                        colorPrimaryActive: "#AA0A22",
                        borderRadius: 0,


                        colorBgContainer: '#FFFFFF',
                    },

                }}
            >
                <Table dataSource={dataSource} columns={columns} bordered={true}/>
            </ConfigProvider>
            {createUserPopup && createUserPopup}
        </div>
    );
};

export default UsersTable;