import React, {useRef, useState} from 'react';
import {getTariffName} from "../../../../functions/globalFunctions";
import Button from "../../../UI/Button/Button";
import {IReservationPlace} from "../../../../models/models";
import dayjs from "dayjs";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

interface IUserHistoryReservationItem {
    r: IReservationPlace
}

const CompanyHistoryReservationItem = ({r}: IUserHistoryReservationItem) => {
    const [open, setOpen] = useState(false)

    const dayTitle = (number: number) => {
        if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return r.type_place === "Место"? 'дней' : r.type_place ==="Офис"? "месяцев" : "";
        let last_num = number % 10;
        if (last_num === 1) return r.type_place === "Место"? 'день' : r.type_place ==="Офис"? "месяц" : "";
        if ([2, 3, 4].includes(last_num)) return r.type_place === "Место"? 'дня' : r.type_place ==="Офис"? "месяца" : "";
        if ([5, 6, 7, 8, 9, 0].includes(last_num)) return r.type_place === "Место"? 'дней' : r.type_place ==="Офис"? "месяцев" : "";
    }

    const ref = useRef(null)

    useOnClickOutside(ref, () => setOpen(false), [])

    return (
        <div className={"comapnyHistoryReservation-history-item"}>
            <div className={"comapnyHistoryReservation-history-names"}>
                {r.type_place} №
                <span className={"color-red"}>{r.place_number}</span>
            </div>

            <div className={"comapnyHistoryReservation-history-tariff"}>
                {r.type_place === "Место" &&
                    <>Выбранный тариф: <span className={"color-red"}>{getTariffName(r.tariff)}</span></>
                }
                {r.type_place === "Офис" &&
                    <>Цена: <span className={"color-red"}>{r.price}</span></>
                }
            </div>

            <div className={"comapnyHistoryReservation-history-dates"}>
                {r.dates.length} {dayTitle(r.dates.length)}
                <div className={"comapnyHistoryReservation-history-dates-options"} onClick={() => setOpen(!open)}
                     ref={ref}>
                    Подробнее <img src={"/Pictures/ArrowDownRed.svg"}/>
                    <div className={"comapnyHistoryReservation-history-dates-options-content"} data-open={open}>
                        {r.dates.map((d, index) => {
                            return (
                                <div className={"comapnyHistoryReservation-history-dates-option"} key={index}>
                                    {
                                        d.start_date === d.end_date ?
                                            `${dayjs(d.start_date)
                                                .locale("ru")
                                                .format("DD MMM YYYY")}`
                                            :
                                            `${dayjs(d.start_date)
                                                .locale("ru")
                                                .format("DD MMM YYYY")} - 
                                             ${dayjs(d.end_date)
                                                .locale("ru")
                                                .format("DD MMM YYYY")}`
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className={"comapnyHistoryReservation-history-button"}>
                <Button onClick={() => undefined}>
                    Забронировать повторно
                </Button>
            </div>
        </div>
    )
};

export default CompanyHistoryReservationItem;