import React, {useEffect, useState} from 'react';
import {IUser} from "../../../models/user/IUser";
import "./UserSelector.css"

interface IUserSelector{
    options: IUser[],
    value?: IUser
    onChange: (user:IUser) => void,

}
const UserSelector = ({options, value, onChange}:IUserSelector) => {
    const [open, setOpen] = useState(false)

    const [selectedUser, setSelectedUser] = useState(value)
    const [list, setList] = useState<JSX.Element[]>()

    const getList = (val:IUser[]) => {
        setList(val.map((o, index) => {
            let name = `${o.lastName? o.lastName : o.type_company} ${o.firstName? o.firstName : o.name_company} ${o.patronymic? o.patronymic : ""}`
            return (
                <div className={`userSelector-item ${value === o&&" userSelector-item-select"}`}
                     key={index}
                     onClick={() => {
                         setSelectedUser(o)
                         onChange(o)
                         setOpen(false)

                     }}
                >
                    <div  className={"userSelector-item-name"}>{name}</div>
                    <div className={"userSelector-item-email"}>{o.email}</div>
                </div>
            )
        }))
    }

    useEffect(() => {
        getList(options)
    }, [selectedUser, options])

    const search = (value: string) => {
        let val = value.toLowerCase()

        if(value.length > 0){
            let mas = options.filter((u) => {
                if(u.email && u.email.toLowerCase().includes(val)){
                    return u
                }
                if(u.firstName && u.firstName.toLowerCase().includes(val)){
                    return u
                }
                if(u.lastName && u.lastName.toLowerCase().includes(val)){
                    return u
                }
                if(u.patronymic && u.patronymic.toLowerCase().includes(val)){
                    return u
                }
                if(u.type_company && u.type_company.toLowerCase().includes(val)){
                    return u
                }
                if(u.name_company && u.name_company.toLowerCase().includes(val)){
                    return u
                }
            })
            console.log(mas)
            getList(mas)
        }else{
            getList(options)
        }


    }

    return (
        <div className={"userSelector"}>
            <div className={"userSelector-display"} onClick={() => setOpen(!open)}>
                {selectedUser? `${selectedUser.lastName? selectedUser.lastName : selectedUser.type_company} ${selectedUser.firstName? selectedUser.firstName : selectedUser.name_company} ${selectedUser.patronymic? selectedUser.patronymic : ""}` : "Выберите пользователя"}
            </div>
            <div className={"userSelector-list"} data-show={open}>
                <div className={"userSelector-search"}>
                    <input type={"text"} placeholder={"Поиск"} onChange={(e) => search(e.target.value)}/>
                    <img src={"/Pictures/searchIcon.svg"}/>
                </div>
                <div className={"userSelector-list-content"}>{list&&list}</div>
            </div>
        </div>
    );
};

export default UserSelector;