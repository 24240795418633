import React, {useState} from 'react';
import "./GuestTariffsPopup.css"
import NavigateHeader from "../../../UI/NavigateHeader/NavigateHeader";
import Button from "../../../UI/Button/Button";

interface ITariffsPopup {
    onClose: () => void,
}

const GuestTariffsPopup = ({onClose}: ITariffsPopup) => {

    return (
        <div className={"guestTariffsPopup"}>
            <div className={"guestTariffsPopup-content"}>
                <NavigateHeader size={"small"} Text={"Тарифы коворкинга"} onClick={() => onClose()}/>
                <div className={"guestTariffsPopup-tariffs"} >
                    <div className={"guestTariffsPopup-tariff"} onClick={() => alert("Вам нужно быть авторизованным, чтобы выбрать тариф")}>
                        <div>
                            <div className={"guestTariffsPopup-tariff-name"}>
                                Сверхзвуковая
                            </div>
                            <div className={"guestTariffsPopup-tariff-description1"}>
                                Рабочее место на сутки
                            </div>
                            <div className={"guestTariffsPopup-tariff-description2"}>
                                - WI-FI<br/>
                                - Доступ с 8:00 до 23:00<br/>
                                - Личный ящик<br/>
                                - Кухня<br/>
                                - Зона отдыха<br/>
                                - Печать, копирование, сканер<br/>
                                - Клининг, охрана, видеонаблюдение
                            </div>
                        </div>
                        <div className={"guestTariffsPopup-tariff-price"}>
                            500 руб. с НДС
                        </div>
                    </div>
                    <div className={"guestTariffsPopup-tariff"} onClick={() => alert("Вам нужно быть авторизованным, чтобы выбрать тариф")}>
                        <div>
                            <div className={"guestTariffsPopup-tariff-name"}>
                                1-ая космическая
                            </div>
                            <div className={"guestTariffsPopup-tariff-description1"}>
                                Рабочее место на 8 любых дней в течение одного- календарного месяца
                            </div>
                            <div className={"guestTariffsPopup-tariff-description2"}>
                                - WI-FI<br/>
                                - Доступ круглосуточно<br/>
                                - Личный ящик<br/>
                                - Кухня<br/>
                                - Зона отдыха<br/>
                                - Печать, копирование, сканер<br/>
                                - Клининг, охрана, видеонаблюдение
                            </div>
                        </div>
                        <div className={"guestTariffsPopup-tariff-price"}>
                            3000 руб. с НДС
                        </div>
                    </div>
                    <div className={"guestTariffsPopup-tariff"} onClick={() => alert("Вам нужно быть авторизованным, чтобы выбрать тариф")}>
                        <div>
                            <div className={"guestTariffsPopup-tariff-name"}>
                                2-ая космическая
                            </div>
                            <div className={"guestTariffsPopup-tariff-description1"}>
                                Рабочее место на 20 любых дней в течение трех календарных месяцев
                            </div>
                            <div className={"guestTariffsPopup-tariff-description2"}>
                                - WI-FI<br/>
                                - Доступ круглосуточно<br/>
                                - Личный ящик<br/>
                                - Кухня<br/>
                                - Зона отдыха<br/>
                                - Печать, копирование, сканер<br/>
                                - Клининг, охрана, видеонаблюдение<br/>
                                - Переговорная 1 час в день<br/>
                                - Конференц-зал “Горизонт событий” 2 раза в месяц на 3 часа<br/>
                            </div>
                        </div>
                        <div className={"guestTariffsPopup-tariff-price"}>
                            7000 руб. с НДС
                        </div>
                    </div>
                    <div className={"guestTariffsPopup-tariff"} onClick={() => alert("Вам нужно быть авторизованным, чтобы выбрать тариф")}>
                        <div>
                            <div className={"guestTariffsPopup-tariff-name"}>
                                3-я космическая
                            </div>
                            <div className={"guestTariffsPopup-tariff-description1"}>
                                Рабочее место на календарный месяц
                            </div>
                            <div className={"guestTariffsPopup-tariff-description2"}>
                                - WI-FI<br/>
                                - Доступ круглосуточно<br/>
                                - Личный ящик<br/>
                                - Кухня<br/>
                                - Зона отдыха<br/>
                                - Печать, копирование, сканер<br/>
                                - Клининг, охрана, видеонаблюдение<br/>
                                - Переговорная 1 час в день<br/>
                                - Конференц-зал “Горизонт событий” 2 раза в месяц на 3 часа<br/>
                                - Фиксированное место на выбор
                            </div>
                        </div>
                        <div className={"guestTariffsPopup-tariff-price"}>
                            7500 руб. с НДС
                        </div>
                    </div>
                </div>
                <div className={"guestTariffsPopup-tariff-button"}>
                    <Button onClick={() => {
                        onClose()
                    }}
                        size={"small"}
                    >
                        Закрыть
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default GuestTariffsPopup;