import React, {useContext} from 'react';
import Burger from "../Burger/Burger";
import {Link} from "react-router-dom";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";

const CompanyHeader = () => {
    const {store} = useContext(Context)
    return (
        <div className={"headerUser"}>
            <div className={"headerUser-info"}>
                <Burger/>
                <div className={"headerUser-info-image"}>
                    <img src={store.user.avatar_url? store.user.avatar_url : "/Pictures/avatarIcon.svg"}/>
                </div>
                <div className={"headerUser-info-names"}>
                    <div>{store.user.type_company} {store.user.name_company}</div>
                    <div>{store.user.email}</div>
                </div>
            </div>
            <div className={"headerUser-logo"}>
                <div className="headerUser-logo-text">горизонт событий</div>
                <div className="headerUser-logo-item">
                    <img src="Pictures/horizonLogo.svg"/>
                </div>
                <div className="headerUser-logo-text">коворкинг</div>
            </div>
            <div className={"headerUser-notify"}>
                <img src={"Pictures/morionLogo.svg"}/>
                <img src={"Pictures/alarm.svg"}/>
            </div>
        </div>
    );
};

export default observer(CompanyHeader);