import React, {useContext, useEffect, useState} from 'react';
import "./CreateNewUser.css"
import NavigateHeader from "../../../../UI/NavigateHeader/NavigateHeader";
import Input from "../../../../UI/Input/Input";
import Button from "../../../../UI/Button/Button";
import {Context} from "../../../../../index";
import AdminService from "../../../../../services/AdminService";

interface ICreateNewUser {
    onCreate: () => void,
    onClose: () => void
}

interface IUserReg {
    firstName: { value: string, error: [string] | undefined },
    lastName: { value: string, error: [string] | undefined },
    patr: { value: string, error: [string] | undefined },
    email: { value: string, error: [string] | undefined },
    phone: { value: string, error: [string] | undefined },
    password: { value: string, error: [string] | undefined },
}


const CreateNewUser = ({onCreate, onClose}: ICreateNewUser) => {
    const {store} = useContext(Context)

    const [fieldsData, setFieldsData] = useState<IUserReg>({
        firstName: {value: "", error: undefined},
        lastName: {value: "", error: undefined},
        patr: {value: "", error: undefined},
        email: {value: "", error: undefined},
        phone: {value: "", error: undefined},
        password: {value: "", error: undefined},
    })
    const [doublePassword, setDoublePassword] = useState("");

    const [canEnter, setCanEnter] = useState(false);

    const [errorDoublePassword, setErrorDoublePassword] = useState<[string]>();

    useEffect(() => {
        if (doublePassword !== fieldsData.password.value) {
            setErrorDoublePassword(['Пароли не совпадают'])
            setCanEnter(false);
        }

        if (Object.values(fieldsData).every(field => field.value !== "") && doublePassword ) {
            if (doublePassword === fieldsData.password.value) {
                setCanEnter(true);
            } else {
                setErrorDoublePassword(['Пароли не совпадают!'])
                setCanEnter(false);
            }
        } else {
            setCanEnter(false);
        }
    }, [fieldsData, doublePassword])

    const ChangeFieldValues = (fieldName: string, value: string, error: [string] | undefined) => {
        setFieldsData((prevInputValues) => ({
            ...prevInputValues,
            [fieldName]: {value, error: error},
        }));
    }

    const Registration = () => {

        let errorFlag: boolean = false;

        if (fieldsData.password.value.length < 6) {
            ChangeFieldValues("password", "", ["Пароль должен содержать не менее 6 символов"]);
            setDoublePassword("");
            setCanEnter(false);
            errorFlag = true;
        }

        if (fieldsData.phone.value.replace(/[+_\(\)\-\s]/g, '').length < 11) {
            ChangeFieldValues("phone", "", ["Телефонный номер введен некорректно"]);
            setCanEnter(false);
            errorFlag = true;
        }

        if (errorFlag === false) {
            store.DataLoadingON()
            let data = {
                FirstName: fieldsData.firstName.value,
                LastName: fieldsData.lastName.value,
                Patronymic: fieldsData.patr.value,
                Email: fieldsData.email.value,
                Phone: fieldsData.phone.value.replace(/[+_\(\)\-\s]/g, ''),
                Password: fieldsData.password.value
            }
            AdminService.createNewUser(data)
                .then(r => {
                    if (r.status === 200) {
                        alert("Пользователь был добавлен")
                        onCreate()
                        onClose()
                    }
                })
                .catch((e) => {
                    if (e.response?.data?.errors){

                        setCanEnter(false);

                        if(e.response.data.errors.Email){
                            ChangeFieldValues("email", fieldsData.email.value, e.response.data.errors.Email)
                        }
                        if(e.response.data.errors.Phone){
                            ChangeFieldValues("phone", fieldsData.phone.value, e.response.data.errors.Phone)
                        }
                        if(e.response.data.errors.Password){
                            ChangeFieldValues("password", fieldsData.password.value, e.response.data.errors.Password)
                        }

                    }
                    else{
                        alert("Что-то пошло не так")
                    }
                })
                .finally(() => store.DataLoadingOFF())


        }
    }


    return (
        <div className={"createNewUser"}>
            <div className={"createNewUser-content"}>
                <NavigateHeader size={"small"} Text={"Создать нового пользователя"} onClick={() => onClose()}/>

                <div className={"createNewUser-input-container"}>
                    <div className={"createNewUser-input-hint"}>Фамилия</div>
                    <Input
                        value={fieldsData.lastName.value}
                        inputSize={"medium"} type={"text"} placeHolder={"Фамилия"}
                        onChange={(e) => {
                            ChangeFieldValues("lastName", e.target.value, undefined)
                        }}
                        errorMsg={fieldsData.lastName.error}
                    />
                </div>

                <div className={"createNewUser-input-container"}>
                    <div className={"createNewUser-input-hint"}>Имя</div>
                    <Input
                        value={fieldsData.firstName.value}
                        inputSize={"medium"} type={"text"} placeHolder={"Имя"}
                        onChange={(e) => {
                            ChangeFieldValues("firstName", e.target.value, undefined)
                        }}
                        errorMsg={fieldsData.firstName.error}
                    />
                </div>

                <div className={"createNewUser-input-container"}>
                    <div className={"createNewUser-input-hint"}>Отчество</div>
                    <Input
                        value={fieldsData.patr.value}
                        inputSize={"medium"} type={"text"} placeHolder={"Отчество"}
                        onChange={(e) => {
                            ChangeFieldValues("patr", e.target.value, undefined)
                        }}
                        errorMsg={fieldsData.patr.error}
                    />
                </div>

                <div className={"createNewUser-input-container"}>
                    <div className={"createNewUser-input-hint"}>Электронная почта</div>
                    <Input
                        value={fieldsData.email.value}
                        inputSize={"medium"} type={"text"}
                        onChange={(e) => {
                            ChangeFieldValues("email", e.target.value, undefined)
                        }}
                        errorMsg={fieldsData.email.error}
                    />
                </div>

                <div className={"createNewUser-input-container"}>
                    <div className={"createNewUser-input-hint"}>Номер телефона</div>
                    <Input
                        value={fieldsData.phone.value}
                        inputSize={"medium"} type={"telephone"}
                        onChange={(e) => {
                            ChangeFieldValues("phone", e.target.value, undefined)
                        }}
                        errorMsg={fieldsData.phone.error}
                    />
                </div>

                <div className={"createNewUser-input-container"}>
                    <div className={"createNewUser-input-hint"}>Пароль</div>
                    <Input
                        value={fieldsData.password.value}
                        inputSize={"medium"} type={"password"} icon={true}
                        onChange={(e) => {
                            ChangeFieldValues("password", e.target.value, undefined)
                        }}
                        errorMsg={fieldsData.password.error}
                    />
                </div>

                <div className={"createNewUser-input-container"}>
                    <div className={"createNewUser-input-hint"}>Подтвердите пароль</div>
                    <Input
                        value={doublePassword}
                        inputSize={"medium"} type={"password"} icon={true}
                        onChange={(e) => {
                            setDoublePassword(e.target.value)
                            setErrorDoublePassword(undefined)
                        }}
                        errorMsg={errorDoublePassword}
                    />
                </div>

                <Button styleProps={"red"}
                        data-canEnter={canEnter}
                        onClick={Registration}
                        disabled={!canEnter}
                >
                    Создать нового пользователя
                </Button>

            </div>
        </div>
    );
};

export default CreateNewUser;