import React, {useState} from 'react';
import "./TariffsPopup.css"
import NavigateHeader from "../../../../UI/NavigateHeader/NavigateHeader";
import Button from "../../../../UI/Button/Button";


interface ITariffsPopup {
    onClose: () => void,
    onSelect: (tariff: string) => void,
    tariff: string
}

const TariffsPopup = ({onClose, onSelect, tariff}: ITariffsPopup) => {
    const [selectTariff, setSelectTariff] = useState(tariff)

    return (
        <div className={"tariffsPopup"}>
            <div className={"tariffsPopup-content"}>
                <NavigateHeader size={"small"} Text={"Выберите тариф"} onClick={() => onClose()}/>
                <div className={"tariffsPopup-tariffs"} >
                    <div className={"tariffsPopup-tariff"} data-sel={selectTariff==="Сверхзвуковая"}
                         onClick={() => setSelectTariff("Сверхзвуковая")}>
                        <div>
                            <div className={"tariffsPopup-tariff-name"}>
                                Сверхзвуковая
                            </div>
                            <div className={"tariffsPopup-tariff-description1"}>
                                Рабочее место на сутки
                            </div>
                            <div className={"tariffsPopup-tariff-description2"}>
                                - WI-FI<br/>
                                - Доступ с 8:00 до 23:00<br/>
                                - Личный ящик<br/>
                                - Кухня<br/>
                                - Зона отдыха<br/>
                                - Печать, копирование, сканер<br/>
                                - Клининг, охрана, видеонаблюдение
                            </div>
                        </div>
                        <div className={"tariffsPopup-tariff-price"}>
                            500 руб. с НДС
                        </div>
                    </div>
                    <div className={"tariffsPopup-tariff"} data-sel={selectTariff==="1-ая космическая"}
                         onClick={() => setSelectTariff("1-ая космическая")}>
                        <div>
                            <div className={"tariffsPopup-tariff-name"}>
                                1-ая космическая
                            </div>
                            <div className={"tariffsPopup-tariff-description1"}>
                                Рабочее место на 8 любых дней в течение одного- календарного месяца
                            </div>
                            <div className={"tariffsPopup-tariff-description2"}>
                                - WI-FI<br/>
                                - Доступ круглосуточно<br/>
                                - Личный ящик<br/>
                                - Кухня<br/>
                                - Зона отдыха<br/>
                                - Печать, копирование, сканер<br/>
                                - Клининг, охрана, видеонаблюдение
                            </div>
                        </div>
                        <div className={"tariffsPopup-tariff-price"}>
                            3000 руб. с НДС
                        </div>
                    </div>
                    <div className={"tariffsPopup-tariff"} data-sel={selectTariff==="2-ая космическая"} onClick={() => setSelectTariff("2-ая космическая")}>
                        <div>
                            <div className={"tariffsPopup-tariff-name"}>
                                2-ая космическая
                            </div>
                            <div className={"tariffsPopup-tariff-description1"}>
                                Рабочее место на 20 любых дней в течение трех календарных месяцев
                            </div>
                            <div className={"tariffsPopup-tariff-description2"}>
                                - WI-FI<br/>
                                - Доступ круглосуточно<br/>
                                - Личный ящик<br/>
                                - Кухня<br/>
                                - Зона отдыха<br/>
                                - Печать, копирование, сканер<br/>
                                - Клининг, охрана, видеонаблюдение<br/>
                                - Переговорная 1 час в день<br/>
                                - Конференц-зал “Горизонт событий” 2 раза в месяц на 3 часа<br/>
                            </div>
                        </div>
                        <div className={"tariffsPopup-tariff-price"}>
                            7000 руб. с НДС
                        </div>
                    </div>
                    <div className={"tariffsPopup-tariff"} data-sel={selectTariff==="3-я космическая"} onClick={() => setSelectTariff("3-я космическая")}>
                        <div>
                            <div className={"tariffsPopup-tariff-name"}>
                                3-я космическая
                            </div>
                            <div className={"tariffsPopup-tariff-description1"}>
                                Рабочее место на календарный месяц
                            </div>
                            <div className={"tariffsPopup-tariff-description2"}>
                                - WI-FI<br/>
                                - Доступ круглосуточно<br/>
                                - Личный ящик<br/>
                                - Кухня<br/>
                                - Зона отдыха<br/>
                                - Печать, копирование, сканер<br/>
                                - Клининг, охрана, видеонаблюдение<br/>
                                - Переговорная 1 час в день<br/>
                                - Конференц-зал “Горизонт событий” 2 раза в месяц на 3 часа<br/>
                                - Фиксированное место на выбор
                            </div>
                        </div>
                        <div className={"tariffsPopup-tariff-price"}>
                            7500 руб. с НДС
                        </div>
                    </div>
                </div>
                <div className={"tariffsPopup-tariff-button"}>
                    <Button onClick={() => {
                        onSelect(selectTariff)
                        onClose()
                    }}
                        size={"small"}
                    >
                        Выбрать тариф
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TariffsPopup;