import React, {useContext, useEffect, useRef, useState} from 'react';

import {Calendar, ConfigProvider, Switch} from "antd";
import Button from "../../../../UI/Button/Button";

import "./AdminReservationPopup.css"
import NavigateHeader from "../../../../UI/NavigateHeader/NavigateHeader";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";

import TariffsPopup from "../TariffsPopup/TariffsPopup";
import {Context} from "../../../../../index";
import InteractiveMapService from "../../../../../services/InteractiveMapService";

import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import CustomCalendarMonth from "../../../../UI/CustomCalendar/CustomCalendarMonth";
import UserSelector from "../../../../UI/UserSelector/UserSelector";
import {IUser} from "../../../../../models/user/IUser";

interface IReservationPopup {
    onClose: () => void,
    data: any,
    onUpdate: () => void
}

const ReservationOfficePopup = ({onClose, data, onUpdate}: IReservationPopup) => {
    const {store} = useContext(Context)
    const ref = useRef(null)
    useOnClickOutside(ref, () => onClose())

    const [place, setPlace] = useState(data.place)
    const [selectedDate, setSelectedDate] = useState<{ start_date: string, end_date: string }[]>()
    const [users, setUsers] = useState<IUser[]>(data.users)
    const [selectedUser, setSelectedUser] = useState<IUser>()
    const onSelectMonth = (date: dayjs.Dayjs[]) => {
        setSelectedDate(date.map((d) => {
            let dt = dayjs(d)
            return {
                start_date: dt.startOf("month").format("YYYY-MM-DD"),
                end_date: dt.endOf("month").format("YYYY-MM-DD")
            }
        }))
    }

    const reservationOffice = () => {
        let data = {
            place_id: place.id,
            dates: selectedDate
        }
        store.DataLoadingON()
        InteractiveMapService.reservationPlace(data)
            .then(() => {
                alert("Офис был забронирован")
                onClose()
                store.checkAuth()
                onUpdate()
            })
            .catch(() => {
                alert("Что-то пошло не так")
            })
            .finally(() => store.DataLoadingOFF())
    }

    return (
        <div className={"adminReservationPopup"}>
            <div className={"adminReservationPopup-container"} ref={ref}>
                <NavigateHeader size={"small"} Text={"Бронирование офиса"}
                                onClick={() => onClose()}/>
                <div className={"adminReservationPopup-content"}>

                    <div className={"adminReservationPopup-place-container"}>
                        <div className={"adminReservationPopup-place"}>
                            {place.element.type} №
                            <div>{place.number_place}</div>
                        </div>

                        <div className={"adminReservationPopup-user"}>
                            <UserSelector options={users} value={selectedUser} onChange={(user) => setSelectedUser(user)}/>
                        </div>

                        <div className={"adminReservationPopup-place-price"}>
                            Цена аренды офиса на месяц: <span className={"color-red"}>{place.price}</span>
                        </div>

                        <div className={"adminReservationPopup-calendar"}>
                            Выберите месяц
                            {(!selectedUser) &&
                                <div className={"adminReservationPopup-calendar-cancel"}>
                                    Выберите пользователя, чтобы забронировать
                                </div>
                            }
                            <CustomCalendarMonth
                                onSelect={(date) => onSelectMonth(date)}

                                disabledDates={data.reservation ? data.reservation.map((r: {
                                    start_date: any;
                                }) => r.start_date) : []}
                                multiSelect={true}
                            />
                        </div>
                        {/*<div className={"adminReservationPopup-result"}>
                        Итог: {}
                    </div>*/}
                        <div className={"adminReservationPopup-buttons"}>
                            <Button onClick={() => onClose()} size={"small"} styleProps={"white1"}>
                                Отменить
                            </Button>
                            <Button onClick={reservationOffice} size={"small"}>
                                Забронировать
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReservationOfficePopup;