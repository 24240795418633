import React, {useContext, useEffect, useRef, useState} from 'react';
import DropDown from "../../../UI/DropDown/DropDown";
import {Calendar, ConfigProvider, Switch} from "antd";
import Button from "../../../UI/Button/Button";
import CustomCalendar from "../../../UI/CustomCalendar/CustomCalendar";
import "./ReservationPopup.css"
import NavigateHeader from "../../../UI/NavigateHeader/NavigateHeader";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import {IPlace} from "../../../../models/models";
import TariffsPopup from "../TariffsPopup/TariffsPopup";
import {Context} from "../../../../index";
import InteractiveMapService from "../../../../services/InteractiveMapService";
import {getTariffName} from "../../../../functions/globalFunctions";

interface IReservationPopup {
    onClose: () => void,
    data: any,
    onUpdate: () => void
}

const ReservationPlacePopup = ({onClose, data, onUpdate}: IReservationPopup) => {
    const {store} = useContext(Context)
    const [place, setPlace] = useState(data.place)
    const [selectedDate, setSelectedDate] = useState<[]>([])
    const [selectedTariff, setSelectedTariff] = useState<number>(store.user.tariff)
    const [tariffsPopup, setTariffsPopup] = useState<JSX.Element | null>(null)
    const ref = useRef(null)
    const [isFixedPlace, setFixefPlace] = useState(false)
    useOnClickOutside(ref, () => onClose())

    const [userFreeDays, setUserFreeDays] = useState(store.user.daysFree )

    console.log(store.user.daysFree)

    const onChangeTariff = (tariff: string) => {
        switch (tariff) {
            case "Сверхзвуковая":
                setSelectedTariff(1)
                setUserFreeDays(1)
                return
            case "1-ая космическая":
                setSelectedTariff(2)
                setUserFreeDays(8)
                return
            case "2-ая космическая":
                setSelectedTariff(3)
                setUserFreeDays(20)
                return
            case "3-я космическая":
                setSelectedTariff(4)
                setUserFreeDays(1)
                return
        }
    }

    const getTitleCalendar = () => {
        switch (selectedTariff) {
            case 1:
                return "Выберите дату"
            case 2:
                return `Выберите от 1 до ${userFreeDays} дат`
            case 3:
                return `Выберите от 1 до ${userFreeDays} дат`
            case 4:
                return "Выберите дату начала"
            default:
                return "Выберите дату"
        }
    }

    const ReservationPlace = () => {
        if(selectedDate.length > 0){
            let data = {
                place_id: place.id,
                tariff: selectedTariff,
                dates: selectedDate.map(d => {
                    return {start_date: d, end_date: d}
                })
            }
            store.DataLoadingON()
            InteractiveMapService.reservationPlace(data)
                .then(() => {
                    alert("Место было забронировано")
                    onClose()
                    store.checkAuth()
                    onUpdate()
                })
                .catch(() => {
                    alert("Что-то пошло не так")
                })
                .finally(() => store.DataLoadingOFF())
        }
        else{
            alert("Вы не выбрали дни")
        }
    }

    return (
        <>
            {tariffsPopup ? tariffsPopup :
                <div className={"reservationPopup"} style={{display: tariffsPopup ? "none" : ""}}>
                    <div className={"reservationPopup-content"} ref={ref}>
                        <NavigateHeader size={"small"} Text={"Бронирование места"}
                                        onClick={() => onClose()}/>
                        <div className={"reservationPopup-place"}>
                            {place.element.type} №
                            <div>{place.number_place}</div>
                        </div>

                        <div className={"reservationPopup-tariff"}>
                            <Button
                                onClick={() => {
                                    if(store.user.tariff || store.user.daysFree > 0 ) {
                                        alert("Вы не можете выбрать новый тариф, так как у вас есть невыбранные дни на вашем текущем тарифе")
                                    }
                                    else {
                                        setTariffsPopup(<TariffsPopup
                                            onClose={() => setTariffsPopup(null)}
                                            onSelect={(tariff) => onChangeTariff(tariff)}
                                            tariff={""}
                                        />)
                                    }
                                }}
                                size={"small"}
                            >
                                {selectedTariff ? `Ваш тариф: ${getTariffName(selectedTariff)}` : "Выберите тариф"}
                                <img src={"/Pictures/ArrowWhite.svg"}/>
                            </Button>
                            {selectedTariff === 4 &&
                                <div>
                                    Данное место будет забронированно как фиксированное на <span className={"color-red"}>календарный месяц</span>
                                    {/*<ConfigProvider
                                    theme={{
                                        token: {

                                            fontFamily: "Montserrat",
                                            colorPrimary: '#AA0A22',
                                            colorPrimaryActive: "#AA0A22",
                                            borderRadius: 10,
                                            colorBgContainer: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <Switch onChange={(e) => setFixefPlace(e)}/>
                                </ConfigProvider>*/}
                                </div>
                            }
                        </div>

                        <div className={"reservationPopup-calendar"}>
                            {getTitleCalendar()}
                            {((!selectedTariff || selectedTariff === 0)) &&
                                <div className={"reservationPopup-calendar-cancel"}>
                                    Выберите тариф, чтобы забронировать
                                </div>
                            }
                            <CustomCalendar
                                maxDates={userFreeDays}
                                userDates={data.reservationUser? data.reservationUser.map((r: { start_date: any; }) => r.start_date) : []}
                                disabledDates={data.reservationOther ? data.reservationOther.map((r: { start_date: any; }) => r.start_date) : []}
                                onChange={(dates) => setSelectedDate(dates)}
                                multiSelect={selectedTariff !== 1 && selectedTariff !== 4}
                                isFixedPlace={selectedTariff === 4}
                            />
                        </div>
                        {/*<div className={"reservationPopup-result"}>
                        Итог: {}
                    </div>*/}
                        <div className={"reservationPopup-buttons"}>
                            <Button onClick={() => onClose()} size={"small"} styleProps={"white1"}>
                                Отменить
                            </Button>
                            <Button onClick={ReservationPlace} size={"small"} disabled={!selectedTariff}>
                                Забронировать
                            </Button>
                        </div>

                    </div>
                </div>
            }
        </>
    );
};

export default ReservationPlacePopup;