import React, {useContext, useEffect, useRef, useState} from 'react';
import DropDown from "../../../UI/DropDown/DropDown";
import {Calendar, ConfigProvider, Switch} from "antd";
import Button from "../../../UI/Button/Button";
import CustomCalendar from "../../../UI/CustomCalendar/CustomCalendar";
import "./GuestReservationPopup.css"
import NavigateHeader from "../../../UI/NavigateHeader/NavigateHeader";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import {IPlace} from "../../../../models/models";
import {Context} from "../../../../index";
import InteractiveMapService from "../../../../services/InteractiveMapService";
import {getTariffName} from "../../../../functions/globalFunctions";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import CustomCalendarMonth from "../../../UI/CustomCalendar/CustomCalendarMonth";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {useNavigate} from "react-router-dom";
import ChooseRole from "../../Login/ChooseRole/ChooseRole";

interface IReservationPopup {
    onClose: () => void,
    data: any,
    onUpdate: () => void
}

const GuestReservationOfficePopup = ({onClose, data, onUpdate}: IReservationPopup) => {
    const navigate = useNavigate()
    const {store} = useContext(Context)
    const ref = useRef(null)
    useOnClickOutside(ref, () => onClose())

    const [place, setPlace] = useState(data.place)
    const [selectedDate, setSelectedDate] = useState<{ start_date: string, end_date: string }[]>()
    const [showChooseRole, setShowChooseRole] = useState(false)

    const onSelectMonth = (date: any[]) => {
        setSelectedDate(date.map((d) => {
            let nd = dayjs(d)
            return {
                start_date: nd.startOf("month").format("YYYY-MM-DD"),
                end_date: nd.endOf("month").format("YYYY-MM-DD")
            }
        }))
    }

    const reservationOffice = () => {
        let data = {
            place_id: place.id,
            dates: selectedDate
        }
        store.DataLoadingON()
        InteractiveMapService.reservationPlace(data)
            .then(() => {
                alert("Офис был забронирован")
                onClose()
                store.checkAuth()
                onUpdate()
            })
            .catch(() => {
                alert("Что-то пошло не так")
            })
            .finally(() => store.DataLoadingOFF())
    }

    return (
        <>{showChooseRole ? <ChooseRole onClose={() => setShowChooseRole(false)}/> :

            <div className={"guestReservationPopup"}>
                <div className={"guestReservationPopup-content"} ref={ref}>
                    <NavigateHeader size={"small"} Text={"Бронирование офиса"}
                                    onClick={() => onClose()}/>
                    <div className={"guestReservationPopup-place"}>
                        {place.element.type} №
                        <div>{place.number_place}</div>
                    </div>

                    <div className={"guestReservationPopup-place-price"}>
                        Цена аренды офиса на месяц: <span className={"color-red"}>{place.price}</span>
                    </div>

                    <div className={"guestReservationPopup-calendar"}>
                        Календарь

                        <CustomCalendarMonth
                            isGuest
                            maxDates={0}
                            multiSelect={true}
                            onSelect={(date) => onSelectMonth(date)}
                            userDates={data.reservationUser ? data.reservationUser.map((r: {
                                start_date: any;
                            }) => r.start_date) : []}
                            disabledDates={data.reservationOther ? data.reservationOther.map((r: {
                                start_date: any;
                            }) => r.start_date) : []}
                        />
                    </div>
                    {/*<div className={"guestReservationPopup-result"}>
                        Итог: {}
                    </div>*/}
                    Вы должны быть авторизованным пользователем, чтобы забронировать
                    <div className={"guestReservationPopup-buttons"}>

                        <Button onClick={() => navigate(SpaRoutes.LOGIN)} size={"small"}>
                            Войти
                        </Button>
                        <Button onClick={() => setShowChooseRole(true)} size={"small"}>
                            Зарегистрироваться
                        </Button>
                    </div>

                </div>
            </div>
        }
        </>
    );
};

export default GuestReservationOfficePopup;