import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Routes, Route} from "react-router-dom";
import Map from "./components/ProtectedPages/Map/Map";
import Test from "./components/Test/Test";
import RequireAuth from "./components/Auth/RequireAuth";
import Main from "./components/PublicPages/Main/Main";
import {SpaRoutes} from "./Routes/spaRoutes";

import Login from "./components/PublicPages/Login/Login";
import LoggedIn from "./components/ProtectedPages/LoggedIn/LoggedIn";
import {observer} from "mobx-react-lite";
import MapEdit from "./components/ProtectedPages/Admin/MapEdit/MapEdit";
import Registration from "./components/PublicPages/Registration/CompanyRegistration/CompanyRegistration";
import CompanyRegistration from "./components/PublicPages/Registration/CompanyRegistration/CompanyRegistration";
import UserRegistration from "./components/PublicPages/Registration/UserRegistration/UserRegistration";
import UserProfile from "./components/ProtectedPages/User/UserProfile/UserProfile";
import {HistoryReservation, Home, Profile} from "./components/ProtectedPages/RoleBasedComponent";
import AdminMap from "./components/ProtectedPages/Admin/AdminMap/AdminMap";
import UsersTable from "./components/ProtectedPages/Admin/UsersTable/UsersTable";
import CompaniesTable from "./components/ProtectedPages/Admin/CompaniesTable/CompaniesTable";
import GuestMap from "./components/PublicPages/GuestMap/GuestMap";


const ROLES = {
    'Guest': 'Guest',
    'User': 'User',
    'Company': 'Company',
    'Admin': 'Admin'
}

function App() {
    return (
        <>
            <Routes>

                <Route path={SpaRoutes.LOGIN} element={<Login/>}/>
                <Route path={SpaRoutes.USER_REGISTRATION} element={<UserRegistration/>}/>
                <Route path={SpaRoutes.COMPANY_REGISTRATION} element={<CompanyRegistration/>}/>

                <Route element={<RequireAuth allowedRole={[ROLES.Guest]}/>}>
                    <Route path={SpaRoutes.GUEST_MAP} element={<GuestMap/>}/>
                </Route>

                {/*<Route element={<RequireAuth allowedRole={[ROLES.Guest]}/>}>

                    <Route path={SpaRoutes.MAIN} element={<Main/>}/>
                </Route>*/}
                {/*<Route path={SpaRoutes.REGISTRATION} element={<Main/>}/>*/}

                <Route element={<RequireAuth allowedRole={[ROLES.User, ROLES.Company, ROLES.Admin]}/>}>
                    <Route path={SpaRoutes.Home} element={<Home/>}/>
                    <Route path={SpaRoutes.HISTORY} element={<HistoryReservation/>}/>
                </Route>

                <Route element={<RequireAuth allowedRole={[ROLES.User, ROLES.Company]}/>}>
                    <Route path={SpaRoutes.Home} element={<Home/>}/>
                    <Route path={SpaRoutes.PROFILE} element={<Profile/>}/>
                </Route>

                <Route element={<RequireAuth allowedRole={[ROLES.User]}/>}>

                </Route>

                <Route element={<RequireAuth allowedRole={[ROLES.Company]}/>}>
                    
                </Route>

                <Route element={<RequireAuth allowedRole={[ROLES.Admin]}/>}>
                    <Route path={SpaRoutes.ADMIN.INTERACTIVE_MAP_EDIT} element={<MapEdit/>}/>
                    <Route path={SpaRoutes.ADMIN.INTERACTIVE_MAP} element={<AdminMap/>}/>
                    <Route path={SpaRoutes.ADMIN.USERS} element={<UsersTable/>}/>
                    <Route path={SpaRoutes.ADMIN.COMPANIES} element={<CompaniesTable/>}/>
                </Route>
            </Routes>
        </>
    );
}

export default observer(App);
