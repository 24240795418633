import React, {useContext, useEffect, useState} from 'react';
import "../../Login/Login.css";
import Input from "../../../UI/Input/Input";
import {Context} from "../../../../index";
import {useNavigate} from "react-router-dom";
import Button from "../../../UI/Button/Button";
import {observer} from "mobx-react-lite";
import AuthHeader from "../../../UI/AuthHeader/AuthHeader";

interface IUserReg {
    firstName:{value:string, error: [string]|undefined},
    lastName:{value:string, error: [string]|undefined},
    patr:{value:string, error: [string]|undefined},
    email:{value:string, error: [string]|undefined},
    phone:{value:string, error: [string]|undefined},
    password:{value:string, error: [string]|undefined},
}

const UserRegistration = ( ) => {
    const {store} = useContext(Context)

    const navigate = useNavigate();

    const [fieldsData, setFieldsData] = useState<IUserReg>({
        firstName:{value:"", error: undefined},
        lastName:{value:"", error: undefined},
        patr:{value:"", error: undefined},
        email:{value:"", error: undefined},
        phone:{value:"", error: undefined},
        password:{value:"", error: undefined},
    })
    const [doublePassword, setDoublePassword] = useState("");

    const [agree, setAgree] = useState(false);
    const [canEnter, setCanEnter] = useState(false);

    const [errorDoublePassword, setErrorDoublePassword] = useState<[string]>();

    useEffect(() => {
        if (doublePassword !== fieldsData.password.value){
            setErrorDoublePassword(['Пароли не совпадают'])
            setCanEnter(false);
        }

        if (Object.values(fieldsData).every(field => field.value !== "") && doublePassword && agree){
            if (doublePassword === fieldsData.password.value){
                setCanEnter(true);
            }
            else{
                setErrorDoublePassword(['Пароли не совпадают!'])
                setCanEnter(false);
            }
        }
        else{
            setCanEnter(false);
        }
    }, [fieldsData, doublePassword, agree])


    const Registration = () => {

        let errorFlag:boolean = false;

        if(fieldsData.password.value.length < 6){
            ChangeFieldValues("password", "", ["Пароль должен содержать не менее 6 символов"]);
            setDoublePassword("");
            setCanEnter(false);
            errorFlag = true;
        }

        if(fieldsData.phone.value.replace(/[+_\(\)\-\s]/g, '').length < 11){
            ChangeFieldValues("phone", "", ["Телефонный номер введен некорректно"]);
            setCanEnter(false);
            errorFlag = true;
        }

        if(errorFlag === false){
            let data = {
                FirstName: fieldsData.firstName.value,
                LastName: fieldsData.lastName.value,
                Patronymic: fieldsData.patr.value,
                Email: fieldsData.email.value,
                Phone: fieldsData.phone.value.replace(/[+_\(\)\-\s]/g, ''),
                Password: fieldsData.password.value
            }
            store.RegisterUser(data)
                .then(r => {
                    if (r.status === 200) {
                        let data = {
                            email: fieldsData.email.value,
                            password: fieldsData.password.value,
                            rememberMe: false
                        }
                        store.login(data)
                            .then(r => {
                                if (r.status === 200) {
                                    navigate("/");
                                }
                            })
                            .catch((e) => {
                                alert("Что-то пошло не так");
                                console.log(e.message);
                                navigate("/login");
                            })
                    }
                })
                .catch((e) => {

                    if (e.response?.data?.errors){

                        setCanEnter(false);

                        if(e.response.data.errors.Email){
                            ChangeFieldValues("email", fieldsData.email.value, e.response.data.errors.Email)
                        }
                        if(e.response.data.errors.Phone){
                            ChangeFieldValues("phone", fieldsData.phone.value, e.response.data.errors.Phone)
                        }
                        if(e.response.data.errors.Password){
                            ChangeFieldValues("password", fieldsData.password.value, e.response.data.errors.Password)
                        }

                    }
                })
        }

    }

    const ChangeFieldValues = (fieldName:string, value:string, error:[string]|undefined) => {
        setFieldsData((prevInputValues) => ({
            ...prevInputValues,
            [fieldName]: { value, error: error },
        }));
    }

    return (
        <>
            <AuthHeader size={"medium"}/>
            <div className={"login-content"}>
                <div className={"login-form"}>
                    <div className={"login-text"}>Регистрация</div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Фамилия</div>
                        <Input
                            value={fieldsData.lastName.value}
                            inputSize={"medium"} type={"text"} placeHolder={"Фамилия"}
                            onChange={(e) => {
                                ChangeFieldValues("lastName", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.lastName.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Имя</div>
                        <Input
                            value={fieldsData.firstName.value}
                            inputSize={"medium"} type={"text"} placeHolder={"Имя"}
                            onChange={(e) => {
                                ChangeFieldValues("firstName", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.firstName.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Отчество</div>
                        <Input
                            value={fieldsData.patr.value}
                            inputSize={"medium"} type={"text"} placeHolder={"Отчество"}
                            onChange={(e) => {
                                ChangeFieldValues("patr", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.patr.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Электронная почта</div>
                        <Input
                            value={fieldsData.email.value}
                            inputSize={"medium"} type={"text"}
                            onChange={(e) => {
                                ChangeFieldValues("email", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.email.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Номер телефона</div>
                        <Input
                            value={fieldsData.phone.value}
                            inputSize={"medium"} type={"telephone"}
                            onChange={(e) => {
                                ChangeFieldValues("phone", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.phone.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Пароль</div>
                        <Input
                            value={fieldsData.password.value}
                            inputSize={"medium"} type={"password"} icon={true}
                            onChange={(e) => {
                                ChangeFieldValues("password", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.password.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Подтвердите пароль</div>
                        <Input
                            value={doublePassword}
                            inputSize={"medium"} type={"password"} icon={true}
                            onChange={(e) => {
                                setDoublePassword(e.target.value)
                                setErrorDoublePassword(undefined)
                            }}
                            errorMsg={errorDoublePassword}
                        />
                    </div>

                    <div className={"login-rememberMe"}>
                        <input type={"checkbox"} onChange={(e) => setAgree(e.target.checked)}/>
                        <div>Согласие на обработку персональных данных</div>
                    </div>


                    <Button styleProps={"red"}
                            data-canEnter={canEnter}
                            onClick={Registration}
                            disabled={!canEnter}
                    >
                        Зарегистрироваться
                    </Button>

                </div>
            </div>
        </>
    );
};

export default observer(UserRegistration);