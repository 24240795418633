import React, {useContext, useEffect, useRef, useState} from 'react';
import DropDown from "../../../UI/DropDown/DropDown";
import {Calendar, ConfigProvider, Switch} from "antd";
import Button from "../../../UI/Button/Button";
import CustomCalendar from "../../../UI/CustomCalendar/CustomCalendar";
import "./GuestReservationPopup.css"
import NavigateHeader from "../../../UI/NavigateHeader/NavigateHeader";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import {IPlace} from "../../../../models/models";

import {Context} from "../../../../index";
import InteractiveMapService from "../../../../services/InteractiveMapService";
import {getTariffName} from "../../../../functions/globalFunctions";
import TariffsPopup from "../../../ProtectedPages/Map/TariffsPopup/TariffsPopup";
import GuestTariffsPopup from "../GuestTariffsPopup/GuestTariffsPopup";
import {useNavigate} from "react-router-dom";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import ChooseRole from "../../Login/ChooseRole/ChooseRole";

interface IReservationPopup {
    onClose: () => void,
    data: any,
    onUpdate: () => void
}

const GuestReservationPlacePopup = ({onClose, data, onUpdate}: IReservationPopup) => {
    const navigate = useNavigate()
    const {store} = useContext(Context)
    const [place, setPlace] = useState(data.place)
    const [selectedDate, setSelectedDate] = useState<[]>([])
    const [selectedTariff, setSelectedTariff] = useState<number>(store.user.tariff)
    const [tariffsPopup, setTariffsPopup] = useState<JSX.Element | null>(null)
    const ref = useRef(null)
    const [isFixedPlace, setFixefPlace] = useState(false)
    const [showChooseRole, setShowChooseRole] = useState(false)
    useOnClickOutside(ref, () => onClose())

    const [userFreeDays, setUserFreeDays] = useState(store.user.daysFree)

    console.log(store.user.daysFree)

    const onChangeTariff = (tariff: string) => {
        switch (tariff) {
            case "Сверхзвуковая":
                setSelectedTariff(1)
                setUserFreeDays(1)
                return
            case "1-ая космическая":
                setSelectedTariff(2)
                setUserFreeDays(8)
                return
            case "2-ая космическая":
                setSelectedTariff(3)
                setUserFreeDays(20)
                return
            case "3-я космическая":
                setSelectedTariff(4)
                setUserFreeDays(1)
                return
        }
    }

    const getTitleCalendar = () => {
        switch (selectedTariff) {
            case 1:
                return "Выберите дату"
            case 2:
                return `Выберите от 1 до ${userFreeDays} дат`
            case 3:
                return `Выберите от 1 до ${userFreeDays} дат`
            case 4:
                return "Выберите дату начала"
            default:
                return "Выберите дату"
        }
    }

    /*window.addEventListener('popstate', (e) => {
        e.preventDefault()
        console.log(12313123)
        onClose()
        window.removeEventListener('popstate', () => {})
    });*/

    return (
        <>
            {tariffsPopup ? tariffsPopup :
                showChooseRole ? <ChooseRole onClose={() => setShowChooseRole(false)}/> :
                    <div className={"guestReservationPopup"} style={{display: tariffsPopup ? "none" : ""}}>
                        <div className={"guestReservationPopup-content"} ref={ref}>
                            <NavigateHeader size={"small"} Text={"Информация о месте"}
                                            onClick={() => onClose()}/>
                            <div className={"guestReservationPopup-place"}>
                                {place.element.type} №
                                <div>{place.number_place}</div>
                            </div>

                            <div className={"guestReservationPopup-tariff"}>
                                <Button
                                    onClick={() => {
                                        setTariffsPopup(<GuestTariffsPopup
                                            onClose={() => setTariffsPopup(null)}
                                        />)
                                    }}
                                    size={"small"}
                                >
                                    Тарифы коворкинга
                                    <img src={"/Pictures/ArrowWhite.svg"}/>
                                </Button>

                            </div>

                            <div className={"guestReservationPopup-calendar"} >
                                Календарь
                                <CustomCalendar
                                    isGuest
                                    maxDates={0}
                                    userDates={data.reservationUser ? data.reservationUser.map((r: {
                                        start_date: any;
                                    }) => r.start_date) : []}
                                    disabledDates={data.reservationOther ? data.reservationOther.map((r: {
                                        start_date: any;
                                    }) => r.start_date) : []}
                                    onChange={(dates) => setSelectedDate(dates)}
                                    multiSelect={selectedTariff !== 1 && selectedTariff !== 4}
                                    isFixedPlace={selectedTariff === 4}
                                />
                            </div>
                            {/*<div className={"guestReservationPopup-result"}>
                        Итог: {}
                    </div>*/}
                            <div className={"guestReservationPopup-hintText"}>
                                Вы должны быть авторизованным пользователем, чтобы забронировать
                            </div>

                            <div className={"guestReservationPopup-buttons"}>

                                <Button onClick={() => navigate(SpaRoutes.LOGIN)} size={"small"}>
                                    Войти
                                </Button>
                                <Button onClick={() => setShowChooseRole(true)} size={"small"}>
                                    Зарегистрироваться
                                </Button>
                            </div>

                        </div>
                    </div>
            }
        </>
    );
};

export default GuestReservationPlacePopup;