import {SpaRoutes} from "../../Routes/spaRoutes";
import {IBurgerList} from "../../models/models";


export const AdminBurgerList:IBurgerList[]=
    [
        {
            name: "Главная",
            link: '/',
        },
        {
            name: "Карта",
            link: SpaRoutes.ADMIN.INTERACTIVE_MAP,
        },
        {
            name: "Редактировать карту",
            link: SpaRoutes.ADMIN.INTERACTIVE_MAP_EDIT,
        },
        {
            name: "Пользователи",
            link: SpaRoutes.ADMIN.USERS,
        },
        {
            name: "Компании",
            link: SpaRoutes.ADMIN.COMPANIES,
        },
        {
            name: "История бронирований",
            link: SpaRoutes.HISTORY,
        },
    ]