import {AxiosResponse} from "axios";
import $api from "../api/axios";
const {ApiRoutes: { Admin }} = require("../Routes/apiRoutes");

export default class AdminService{

    static async getHomeData():Promise<AxiosResponse>{
        return $api.get(Admin.GET_HOME_DATA)
    }
    static async getPlaceInfo(data:object):Promise<AxiosResponse>{
        return $api.post(Admin.GET_PLACE_INFO, data)
    }
    static async reservationPlaceForUser(data:object):Promise<AxiosResponse>{
        return $api.post(Admin.RESERVATION_PLACE_FOR_USER, data)
    }

    static async getTableUsers():Promise<AxiosResponse>{
        return $api.get(Admin.GET_TABLE_USERS)
    }

    static async getTableCompanies():Promise<AxiosResponse>{
        return $api.get(Admin.GET_TABLE_COMPANIES)
    }

    static async createNewUser(data:object):Promise<AxiosResponse>{
        return $api.post(Admin.CREATE_NEW_USER, data)
    }

    static async createNewCompany(data:object):Promise<AxiosResponse>{
        return $api.post(Admin.CREATE_NEW_COMPANY, data)
    }

    static async getAllUsersAndCompanies():Promise<AxiosResponse>{
        return $api.get(Admin.GET_ALL_USERS_AND_COMPANIES)
    }

    static async getHistoryReservation():Promise<AxiosResponse>{
        return $api.get(Admin.GET_HISTORY_RESERVATION)
    }

    static async getFilteredHistoryReservation(data: any):Promise<AxiosResponse>{
        return $api.post(Admin.GET_FILTERED_HISTORY_RESERVATION, data)
    }

}