import {AxiosResponse} from "axios";
import $api from "../api/axios";

const {ApiRoutes: { Company }} = require("../Routes/apiRoutes");

export default class CompanyService{
    static async getCompanyInfo():Promise<AxiosResponse>{
        return $api.get(Company.GET_COMPANY_INFO)
    }

    static async updateCompanyInfo(data: any):Promise<AxiosResponse>{
        return $api.post(Company.UPDATE_COMPANY_INFO, data)
    }

    static async changePassword(data: any):Promise<AxiosResponse>{
        return $api.post(Company.CHANGE_PASSWORD, data)
    }

    static async updateAvatar(data: any):Promise<AxiosResponse>{
        return $api.post(Company.UPDATE_AVATAR, data)
    }

    static async getHistoryReservation():Promise<AxiosResponse>{
        return $api.get(Company.GET_HISTORY_RESERVATION)
    }

    static async getFilteredHistoryReservation(data: any):Promise<AxiosResponse>{
        return $api.post(Company.GET_FILTERED_HISTORY_RESERVATION, data)
    }

}