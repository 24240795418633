import React, {useContext, useEffect, useState} from 'react';
import "../../Login/Login.css";
import Input from "../../../UI/Input/Input";
import {Context} from "../../../../index";
import {useNavigate} from "react-router-dom";
import Button from "../../../UI/Button/Button";
import DropDown, {IDropdownOption} from '../../../UI/DropDown/DropDown';
import {observer} from "mobx-react-lite";
import AuthHeader from "../../../UI/AuthHeader/AuthHeader";
import {SpaRoutes} from "../../../../Routes/spaRoutes";

interface ICompanyReg {
    email:{value:string, error: [string]|undefined},
    phone:{value:string, error: [string]|undefined},
    nameCompany:{value:string, error: [string]|undefined},
    typeCompany:{value:string, error: [string]|undefined},
    inn:{value:string, error: [string]|undefined},
    password:{value:string, error: [string]|undefined},
}

const CompanyRegistration = ( ) => {
    const {store} = useContext(Context)

    const navigate = useNavigate();

    const [fieldsData, setFieldsData] = useState<ICompanyReg>({
        email:{value:"", error: undefined},
        phone:{value:"", error: undefined},
        nameCompany:{value:"", error: undefined},
        typeCompany:{value:"", error: undefined},
        inn:{value:"", error: undefined},
        password:{value:"", error: undefined},
    })
    const [doublePassword, setDoublePassword] = useState("");

    const [agree, setAgree] = useState(false);
    const [canEnter, setCanEnter] = useState(false);

    const [errorDoublePassword, setErrorDoublePassword] = useState<[string]>();

    const companyTypes:IDropdownOption[] = [
        {name: 'ООО'},
        {name: 'ОАО'},
        {name: 'ИП'}
    ];


    useEffect(() => {
        if (doublePassword !== fieldsData.password.value){
            setErrorDoublePassword(['Пароли не совпадают'])
            setCanEnter(false);
        }

        if (Object.values(fieldsData).every(field => field.value !== "") && doublePassword && agree){
            if (doublePassword === fieldsData.password.value){
                setCanEnter(true);
            }
            else{
                setErrorDoublePassword(['Пароли не совпадают'])
                setCanEnter(false);
            }
        }
        else{
            setCanEnter(false);
        }
    }, [fieldsData, doublePassword, agree])


    const Registration = () => {

        let errorFlag:boolean = false;

        if(fieldsData.typeCompany.value !== "ООО" && fieldsData.inn.value.replace(/[_]/g, '').length < 12){
            setCanEnter(false);
            ChangeFieldValues("inn", "", ["ИНН введен некорректно"]);
            errorFlag = true;
        }

        if(fieldsData.typeCompany.value === "ООО" && (fieldsData.inn.value.replace(/[_]/g, '').length > 10 || fieldsData.inn.value.replace(/[_]/g, '').length < 10)){
            setCanEnter(false);
            ChangeFieldValues("inn", "", ["ИНН введен некорректно"]);
            errorFlag = true;
        }

        if(fieldsData.password.value.length < 6){
            ChangeFieldValues("password", "", ["Пароль должен содержать не менее 6 символов"]);
            setDoublePassword("");
            setCanEnter(false);
            errorFlag = true;
        }

        if(fieldsData.phone.value.replace(/[+_\(\)\-\s]/g, '').length < 11){
            ChangeFieldValues("phone", "", ["Телефонный номер введен некорректно"]);
            setCanEnter(false);
            errorFlag = true;
        }

        if(errorFlag === false){
            let data = {
                Email: fieldsData.email.value,
                Phone: fieldsData.phone.value.replace(/[+_\(\)\-\s]/g, ''),
                CompanyName: fieldsData.nameCompany.value,
                Inn: fieldsData.inn.value.replace(/[_]/g, ''),
                TypeOfOrganization: fieldsData.typeCompany.value,
                Password: fieldsData.password.value
            }
            store.RegisterCompany(data)
                .then(r => {
                    if (r.status === 200) {
                        let data = {
                            email: fieldsData.email.value,
                            password: fieldsData.password.value,
                            rememberMe: false
                        }
                        store.login(data)
                            .then(r => {
                                if (r.status === 200) {
                                    navigate(SpaRoutes.Home);
                                }
                            })
                            .catch((e) => {
                                alert("Что-то пошло не так");
                                console.log(e.message);
                                navigate(SpaRoutes.LOGIN);
                            })
                    }
                })
                .catch((e) => {

                    if (e.response?.data?.errors){

                        setCanEnter(false);

                        if(e.response.data.errors.Email){
                            ChangeFieldValues("email", fieldsData.email.value, e.response.data.errors.Email)
                        }
                        if(e.response.data.errors.Phone){
                            ChangeFieldValues("phone", fieldsData.phone.value, e.response.data.errors.Phone)
                        }
                        if(e.response.data.errors.CompanyName){
                            ChangeFieldValues("nameCompany", fieldsData.nameCompany.value, e.response.data.errors.CompanyName)
                        }
                        if(e.response.data.errors.TypeOfOrganization){
                            ChangeFieldValues("typeCompany", fieldsData.typeCompany.value, e.response.data.errors.TypeOfOrganization)
                        }
                        if(e.response.data.errors.Inn){
                            ChangeFieldValues("inn", fieldsData.inn.value, e.response.data.errors.Inn)
                        }
                        if(e.response.data.errors.Password){
                            ChangeFieldValues("password", fieldsData.password.value, e.response.data.errors.Password)
                        }

                    }
                })
        }
    }

    const ChangeFieldValues = (fieldName:string, value:string, error:[string]|undefined) => {
        setFieldsData((prevInputValues) => ({
            ...prevInputValues,
            [fieldName]: { value, error: error },
        }));
    }

    const ChangeTypeField = (item:IDropdownOption) => {
        setFieldsData((prevInputValues) => ({
            ...prevInputValues,
            ["typeCompany"]: { value: item.name, error: undefined },
        }));
    }

    return (
        <>
            <AuthHeader size={"medium"}/>
            <div className={"login-content"}>
                <div className={"login-form"}>
                    <div className={"login-text"}>Регистрация</div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Электронная почта</div>
                        <Input
                            name={"email"}
                            key={"inem"}
                            value={fieldsData.email.value}
                            inputSize={"medium"} type={"text"}
                            onChange={(e) => {
                                ChangeFieldValues("email", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.email.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Номер телефона</div>
                        <Input
                            key={"intorg"}
                            value={fieldsData.phone.value}
                            inputSize={"medium"} type={"telephone"}
                            onChange={(e) => {
                                ChangeFieldValues("phone", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.phone.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Название организации</div>
                        <Input
                            key={"inno"}
                            value={fieldsData.nameCompany.value}
                            inputSize={"medium"} type={"text"}
                            onChange={(e) => {
                                ChangeFieldValues("nameCompany", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.nameCompany.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Номер ИНН</div>
                        <Input
                            key={"ininn"}
                            value={fieldsData.inn.value}
                            inputSize={"medium"} type={"inputMask"} mask={fieldsData.typeCompany.value === "ООО"?"9999999999":"999999999999"}
                            onChange={(e) => {
                                ChangeFieldValues("inn", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.inn.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Тип организации</div>
                        <DropDown
                            options={companyTypes}
                            onChange={ChangeTypeField}
                            icon={true}
                            value={fieldsData.typeCompany.value}
                            errorMsg={fieldsData.typeCompany.error}
                            placeHolder={"Тип организации"}
                            size={"medium"}
                            styleProps={"white"}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Пароль</div>
                        <Input
                            key={"inpswd"}
                            value={fieldsData.password.value}
                            inputSize={"medium"} type={"password"} icon={true}
                            onChange={(e) => {
                                ChangeFieldValues("password", e.target.value, undefined)
                            }}
                            errorMsg={fieldsData.password.error}
                        />
                    </div>

                    <div className={"login-input-container"}>
                        <div className={"login-input-hint"}>Подтвердите пароль</div>
                        <Input
                            key={"inpswdchk"}
                            value={doublePassword}
                            inputSize={"medium"} type={"password"} icon={true}
                            onChange={(e) => {
                                setDoublePassword(e.target.value)
                                setErrorDoublePassword(undefined)
                            }}
                            errorMsg={errorDoublePassword}
                        />
                    </div>

                    <div className={"login-rememberMe"}>
                        <input
                            key={"inchbx"} type={"checkbox"} onChange={(e) => setAgree(e.target.checked)}/>
                        <div>Согласие на обработку персональных данных</div>
                    </div>


                    <Button styleProps={"red"}
                            data-canEnter={canEnter}
                            onClick={Registration}
                            disabled={!canEnter}
                    >
                        Зарегистрироваться
                    </Button>

                </div>
            </div>
        </>
    );
};

export default observer(CompanyRegistration);