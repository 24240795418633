import React, {useContext, useEffect, useRef, useState} from 'react';
import "./UserProfile.css"
import NavigateHeader from "../../../UI/NavigateHeader/NavigateHeader";
import {Context} from "../../../../index";
import Input from "../../../UI/Input/Input";
import InputMask from 'react-input-mask';
import UserService from "../../../../services/UserService";
import Button from "../../../UI/Button/Button";
import {IUserData} from "../../../../models/models";
import GlobalService from "../../../../services/GlobalService";

const UserProfile = () => {
    const {store} = useContext(Context)

    const [userData, setUserData] = useState<IUserData>({})
    const [oldUserData, setOldUserData] = useState<IUserData>({})
    const [password, setPassword] = useState({password: "", newPassword: "", repeatPassword: ""})

    const [avatarFile, setAvatarFile] = useState<File>()
    const [avatarPreview, setAvatarPreview] = useState<string | undefined>()

    const getUserInfo = () => {
        store.DataLoadingON()
        UserService.getUserInfo()
            .then(r => {
                setUserData(r.data.user)
                setOldUserData(r.data.user)
                setAvatarPreview(r.data.user.avatar_url)
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    const UpdateUserInfo = () => {
        store.DataLoadingON()
        UserService.updateUserInfo(userData)
            .then(r => {
                alert("Данные обновлены")
                getUserInfo()
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    const changePassword = () => {
        if(password.newPassword === password.repeatPassword){
            let data = {id: userData.id,password: password.password, newPassword: password.newPassword}
            store.DataLoadingON()
            UserService.changePassword(data)
                .then(() => {
                    alert("Пароль изменен")
                    setPassword({password: "", newPassword: "", repeatPassword: ""})
                })
                .catch((error) => {
                    if(error.response.data.status === "Error_2"){
                        alert("Неправильный текущий пароль")
                    }else{
                        alert("Что-то пошло не так")
                    }

                })
                .finally(() => store.DataLoadingOFF())
        }
    }

    useEffect(() => {
        getUserInfo()
    }, [])

    const inputRef = useRef(null)

    const onBtnPictureClick = () => {
        // @ts-ignore
        inputRef.current?.click();
    }

    const selectMainPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            setAvatarPreview(undefined)
            return
        }
        // I've kept this example simple by using the first image instead of multiple
        setAvatarFile(event.target.files[event.target.files.length - 1])
    };

    useEffect(() => {
        if (!avatarFile) {
            //setElementPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(avatarFile)


        let img = new Image()
        img.src = objectUrl
        img.onload = async () => {
            if (img.width !== img.height) {
                alert(`Изображение не соответсвует формату 1 к 1`)
            } else {
                setAvatarPreview(objectUrl)
                store.DataLoadingON()
                const formData = new FormData();
                formData.append('image', avatarFile);
                userData.avatar_url = await GlobalService.addNewImage(formData).then(r => r.data.data.link)
                UserService.updateAvatar({id: userData.id, avatar_url: userData.avatar_url})
                    .then(() => {
                        alert("Аватар был изменен")
                        store.checkAuth()
                    })
                    .catch(() => {alert("Что-то пошло не так")})
                    .finally(() => {store.DataLoadingOFF()})
            }

        }
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [avatarFile])

    return (
        <div className={"userProfile"}>
            <NavigateHeader Text={"Профиль"} size={"medium"}/>

            <div className={"userProfile-top"}>
                <div className={"userProfile-avatar"}>
                    <div className={"userProfile-avatar-image"}>
                        <img src={avatarPreview? avatarPreview : "/Pictures/avatarIcon.svg"}/>
                    </div>
                    <Button onClick={() => onBtnPictureClick()} type={"edit"} styleProps={"white1"} size={"small"}/>
                    <input
                        ref={inputRef}
                        style={{display: 'none'}}
                        type="file"
                        name={"profilePicture"}
                        onChange={selectMainPicture}
                        accept="image/jpeg, image/png"
                    />
                </div>
                <div className={"userProfile-names"}>
                    <div className={"userProfile-name"}>
                        {oldUserData.lastName} {oldUserData.firstName}
                    </div>
                    <div className={"userProfile-email"}>
                        {oldUserData.email}
                    </div>
                </div>
            </div>
            <hr/>
            <div className={"userProfile-title"}>Учетные данные</div>
            <div className={"userProfile-content"}>
                <div className={"userProfile-item"}>
                    Фамилия
                    <Input type={"text"}
                           value={userData.lastName}
                           onChange={(e) => setUserData({...userData, lastName: e.target.value})}
                           inputSize={"medium"}/>
                </div>
                <div className={"userProfile-item"}>
                    Имя
                    <Input type={"text"}
                           value={userData.firstName}
                           inputSize={"medium"}
                           onChange={(e) => setUserData({...userData, firstName: e.target.value})}
                    />
                </div>
                <div className={"userProfile-item"}>
                    Отчество
                    <Input type={"text"}
                           value={userData.patronymic}
                           inputSize={"medium"}
                           onChange={(e) => setUserData({...userData, patronymic: e.target.value})}
                    />
                </div>
                <div className={"userProfile-item"}>
                    Почта
                    <Input type={"text"}
                           value={userData.email}
                           inputSize={"medium"}
                           onChange={(e) => setUserData({...userData, email: e.target.value})}
                    />
                </div>
                <div className={"userProfile-item"}>
                    Телефон
                    <Input type={"telephone"}
                           value={userData.phone}
                           inputSize={"medium"}
                           onChange={(e) => setUserData({...userData, phone: e.target.value})}
                    />
                </div>
            </div>
            <div className={"userProfile-button"}>
                <Button onClick={() => UpdateUserInfo()} size={"medium"} styleProps={"red"}>
                    Изменить данные
                </Button>
            </div>

            <hr/>
            <div className={"userProfile-title"}>Поменять пароль</div>
            <div className={"userProfile-content"}>
                <div className={"userProfile-item"}>
                    Текущий пароль
                    <Input type={"text"}
                           value={password.password}
                           inputSize={"medium"}
                           onChange={(e) => setPassword({...password, password: e.target.value})}
                    />
                </div>
                <div className={"userProfile-item"}>
                    Новый пароль
                    <Input type={"text"}
                           value={password.newPassword}
                           inputSize={"medium"}
                           onChange={(e) => setPassword({...password, newPassword: e.target.value})}
                    />
                </div>
                <div className={"userProfile-item"}>
                    Повторите пароль
                    <Input type={"text"}
                           value={password.repeatPassword}
                           inputSize={"medium"}
                           onChange={(e) => setPassword({...password, repeatPassword: e.target.value})}
                    />
                </div>
            </div>
            <div className={"userProfile-button"}>
                <Button onClick={() => changePassword()} size={"medium"} styleProps={"red"}>
                    Изменить пароль
                </Button>
            </div>
        </div>
    );
};

export default UserProfile;