import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import Canvas from "../../UI/Canvas/Canvas";
import "./Map.css"
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import html2canvas from "html2canvas";
import {observable, set} from "mobx";
import DropDown, {IDropdownOption} from "../../UI/DropDown/DropDown";
import Button from "../../UI/Button/Button";
import MapFilter from "./MapFilter/MapFilter";
import {IElement, IHint, IInteractiveMap, IMap, IOption, IPlace, IReservationPlace} from "../../../models/models";
import InteractiveMapService from "../../../services/InteractiveMapService";
import {Context} from "../../../index";
import {JSX} from "react/jsx-runtime";

import {useDate} from "../../../hooks/useDate";
import ReservationPlacePopup from "./ReservationPopup/ReservationPlacePopup";
import {observer} from "mobx-react-lite";
import {getTariffName} from "../../../functions/globalFunctions";
import ReservationOfficePopup from "./ReservationPopup/ReservationOfficePopup";
import dayjs from "dayjs";
import MapInteractionCSS from "../../UI/MapInteraction/MapInteractionCSS";
const Map = () => {
    const {store} = useContext(Context)
    let widthScreen = window.innerWidth
    const [scale, setScale] = useState(widthScreen > 450 ? 0.6 : widthScreen/1500)
    const [maps, setMaps] = useState<IMap[]>([])

    const [mapSel, setMapSel] = useState<{ name: string, id: number | null }>({name: "Выберите карту", id: null})
    const [map, setMap] = useState<IMap>()
    const [interactiveMap, setInteractiveMap] = useState<IInteractiveMap>()
    const [places, setPlaces] = useState<IPlace[]>()
    const [reservationPopup, setReservationPopup] = useState<JSX.Element | null>(null)
    const refMap = useRef<HTMLDivElement | null>(null)

    const [pos, setPos] = useState({x: 0, y: 0})

    let listPlaces: JSX.Element[] = []

    // function downloadSVG() {
    //     // @ts-ignore
    //     html2canvas(document.querySelector("#MapEdit")).then(canvas => {
    //         let canvasUrl = canvas.toDataURL("image/jpeg", 0.5);
    //         console.log(canvasUrl);
    //         const createEl = document.createElement('a');
    //         createEl.href = canvasUrl;
    //         createEl.download = "download-this-canvas";
    //         createEl.click();
    //         createEl.remove();
    //     });
    // }

    const changeScale = (e: any) => {
        if (e.target.className.baseVal !== "circle") {
            var delta = e.deltaY || e.detail || e.wheelDelta;
            if (delta < 0 && scale < 200) {
                setScale(scale + 10)
            }
            if (delta > 0 && scale > (widthScreen > 450? 50 : 20)) {
                setScale(scale - 10)
            }
        }
    }

    const getMaps = () => {
        store.DataLoadingON()
        InteractiveMapService.getMaps()
            .then((r) => {
                setMaps(r.data)
                setMapSel(r?.data[0]?.name ? {name: r.data[0].name, id: r.data[0].id} : {
                    name: "Выберите карту",
                    id: null
                })

            })
            .catch()
            .finally(() => store.DataLoadingOFF())
    }

    const getMap = () => {
        store.DataLoadingON()
        InteractiveMapService.getMap({"map_id": mapSel.id})
            .then((r) => {
                setInteractiveMap(r.data)
                setPlaces(r.data.places)
                setMap(r.data.map)
                listPlaces = []
            })
            .catch()
            .finally(() => store.DataLoadingOFF())
    }

    useEffect(() => {
        if (mapSel.name !== "Выберите карту" && mapSel.id) {
            getMap()
        }
    }, [mapSel])

    const changeMap = (e: IDropdownOption) => {
        if (e.name !== mapSel.name && e.id) {
            setMapSel({name: e.name, id: e.id})
            setScale(100)
            setPos({x: 0, y: 0})
        }
    }

    let ArrayMaps: IDropdownOption[] = []
    maps.map((item) => {
        ArrayMaps.push({id: item.id, name: item.name})
    })

    useEffect(() => {
        getMaps()
    }, [])

    const time = useDate()

    const getFilteredMap = (selectedTypes: any, selectedDates: any, selectedOptions: any) => {
        let data = {
            map_id: mapSel.id,
            selectedTypes: selectedTypes,
            selectedDates: selectedDates,
            selectedOptions: selectedOptions.filter((s: { sel: boolean; }) => s.sel).map((s: { name: any; }) => s.name)
        }
        InteractiveMapService.getFilteredPlaces(data)
            .then((r) => {
                console.log(r)
            })
    }

    const openPlace = (id: number) => {
        store.DataLoadingON()
        InteractiveMapService.getPlaceInfo({"place_id": id})
            .then((r) => {
                if (r.data) {
                    if (r.data.place.element.type === "Место")
                        setReservationPopup(<ReservationPlacePopup data={r.data}
                                                                   onUpdate={() => getMap()}
                                                                   onClose={() => setReservationPopup(null)}/>)
                    else if (r.data.place.element.type === "Переговорная")
                        console.log()
                    else if (r.data.place.element.type === "Офис")
                        setReservationPopup(<ReservationOfficePopup data={r.data}
                                                                    onUpdate={() => getMap()}
                                                                    onClose={() => setReservationPopup(null)}/>)
                }
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    const today = dayjs()

    const [isMouseMoving, setIsMouseMoving] = useState(false);

    const handleMouseMove = (e: any) => {

        if (e.buttons === 1) {
            console.log(e)
            setIsMouseMoving(true)
        }
    };

    const handleMouseStop = (e: any) => {
        e.preventDefault()
        console.log(e)
        setTimeout(() => setIsMouseMoving(false), 1)
    };

    const handleTouchMove = (e: any) => {
        console.log(e.touches.length)
        if (e.touches.length >= 1) {
            setIsMouseMoving(true)
        }
    };

    const handleTouchClick = (e: any, p: any) => {
        e.preventDefault()
        setTimeout(() => setIsMouseMoving(false), 1)
        if (!isMouseMoving) {
            p.can_bron ? openPlace(p.id) : alert("Это место сейчас нельзя забронировать")
        }
    };

    return (
        <div className={"interactiveMap"}>
            <div className={"interactiveMap-panel"}>
                <div className={"interactiveMap-panel-info"}>
                    <div className={"interactiveMap-panel-time"}>
                        <img src={"Pictures/timeIcon.svg"} alt={"time"}/>
                        {time.time}
                    </div>
                    {store.user.tariff && store.user.daysFree !== -1 ?
                        <div className={"interactiveMap-panel-tariff"}>
                            {/*<div>Ваш тариф: <span className={"color-red"}>{getTariffName(store.user.tariff)}</span>
                            </div>*/}
                            {store.user.daysFree !== 0 &&
                                <>
                                    <div>Невыбрано <span className={"color-red"}>{store.user.daysFree}</span> дней на
                                        тарифе:
                                    </div>
                                    <span className={"color-red"}>{getTariffName(store.user.tariff)}</span>
                                </>
                            }
                        </div>
                        :
                        <></>
                    }
                </div>
                <div className={"interactiveMap-panel-map"}>
                    <DropDown
                        value={mapSel.name}
                        options={ArrayMaps}
                        onChange={(e) => changeMap(e)}
                        placeHolder={"Выберите этаж"}
                        size={"small"}/>
                </div>
                {/*<MapFilter options={interactiveMap?.options} onSelect={getFilteredMap}/>*/}

            </div>
            {/*1509 903*/}
            {interactiveMap?.map &&
                <MapInteractionCSS
                    defaultValue={{
                        scale: scale,
                        translation: {
                            x: (widthScreen - interactiveMap.map.width * scale) / 2,
                            y: (window.innerHeight - interactiveMap.map.height * (widthScreen > 450? scale : 0.35)) / 2
                        }
                    }}
                    minScale={widthScreen > 450 ? 0.5 : 0.2}
                    maxScale={3}
                    translationBounds={{
                        xMin: (widthScreen - interactiveMap.map.width * scale) / 2 - interactiveMap.map.width * scale / 2,
                        xMax: (widthScreen + interactiveMap.map.width * scale) / 2 - interactiveMap.map.width * scale / 2,
                        yMin: (window.innerHeight - interactiveMap.map.height * scale) / 2 - interactiveMap.map.height * scale / 2,
                        yMax: (window.innerHeight + interactiveMap.map.height * scale) / 2 - interactiveMap.map.height * scale / 2
                    }}
                    onChange={(val: any) => {
                        console.log((window.innerHeight - interactiveMap.map.height * scale) / 2)
                        if (val.scale !== scale)
                            setScale(val.scale)
                    }}

                >

                    <div className={"interactiveMap-content"} style={{

                        width: `${interactiveMap?.map.width}px`,
                        height: `${interactiveMap?.map.height}px`
                    }} ref={refMap}

                    >
                        <img src={interactiveMap?.map.image}/>

                        <svg id={"svgMap"}
                             viewBox={`0 0 ${interactiveMap?.map.width} ${interactiveMap?.map.height}`}>
                            {places?.map((p, index) => {
                                if (p.visible) {
                                    let reservationNow: IReservationPlace | undefined = p.reservationPlace?.find((r) => {
                                        return r.dates.find((d) => dayjs(d.start_date).format("YYYY-MM-DD") === today.format("YYYY-MM-DD"));
                                    });
                                    return (
                                        <g className={"interactiveMap-place"} key={p.id}
                                           style={{transform: `translate(${p.x}px, ${p.y}px)`}}
                                           id={`place-${p.id}`}
                                           data-id={p.id}
                                           data-width={p.element.width} data-height={p.element.height}
                                           onMouseMove={handleMouseMove}
                                           onMouseUp={handleMouseStop}
                                           onClick={(e) => {
                                               e.preventDefault()
                                               console.log(isMouseMoving)
                                               if (!isMouseMoving) {
                                                   p.can_bron ? openPlace(p.id) : alert("Это место сейчас нельзя забронировать")
                                               }
                                           }}
                                           onTouchMove={handleTouchMove}
                                           onTouchEnd={(e) => handleTouchClick(e, p)}
                                           onTouchCancel={(e) => handleTouchClick(e, p)}
                                        >
                                            {!p.element.only_indicator &&
                                                <image href={p.element.image} width={p.element.width} x={0}
                                                       y={0}/>}

                                            {/*<circle className={"circle"}
                                                    cx={p.element.indicator_x}
                                                    cy={p.element.indicator_y}
                                                    r={p.element.indicator_size}
                                                    fill={'url("#image'} stroke="#000000" strokeWidth="0">
                                                </circle>*/}

                                            <image
                                                className={"interactiveMap-place-indicator-image"}
                                                data-info={
                                                    p.can_bron ?
                                                        p.user ?
                                                            "reservation"
                                                            : "free"
                                                        : "lock"
                                                }
                                                x={p.element.indicator_x - p.element.indicator_size}
                                                y={p.element.indicator_y - p.element.indicator_size}
                                                width={p.element.indicator_size * 2}
                                                height={p.element.indicator_size * 2}
                                                href=
                                                    {p.can_bron ?
                                                        p.user ?
                                                            p.user.avatar_url ?
                                                                p.user.avatar_url
                                                                : "/Pictures/userPlaceIcon.svg"
                                                            : "/Pictures/canBronIcon.svg"
                                                        : "/Pictures/lockPlaceIcon.svg"
                                                    }
                                                clipPath={`url(#circleView${index})`}
                                            />
                                            <defs>
                                                <clipPath id={`circleView${index}`}>
                                                    <circle cx={p.element.indicator_x}
                                                            cy={p.element.indicator_y}
                                                            r={p.element.indicator_size} fill="#FFFFFF"/>
                                                </clipPath>
                                            </defs>
                                        </g>
                                    )
                                }
                            })}
                        </svg>
                    </div>
                </MapInteractionCSS>
            }
            {reservationPopup && reservationPopup}
        </div>
    );
};

export default observer(Map);