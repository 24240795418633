import React from 'react';
import NavigateHeader from "../../../UI/NavigateHeader/NavigateHeader";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {useNavigate} from "react-router-dom";

interface IChooseRole{
    onClose: () => void
}

const ChooseRole = ({onClose}: IChooseRole) => {
    const navigate = useNavigate()
    return (
        <div className={"login-chooseRoleRegistration"}>
            <div className={"login-chooseRoleRegistration-content"}>
                <NavigateHeader size={"small"} onClick={() => onClose()}
                                Text={"Выберите роль"}/>
                <div className={"login-chooseRoleRegistration-buttons"}>
                    <div onClick={() => navigate(SpaRoutes.USER_REGISTRATION)}>
                        Физ. лицо
                    </div>
                    <div onClick={() => navigate(SpaRoutes.COMPANY_REGISTRATION)}>
                        Юр. лицо
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChooseRole;