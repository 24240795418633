import React, {useRef, useState} from 'react';
import {getTariffName} from "../../../../functions/globalFunctions";
import Button from "../../../UI/Button/Button";
import {IReservationPlace} from "../../../../models/models";
import dayjs from "dayjs";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

interface IAdminHistoryReservationItem {
    r: IReservationPlace
}

const AdminHistoryReservationItem = ({r}: IAdminHistoryReservationItem) => {
    const [open, setOpen] = useState(false)

    const dayTitle = (number: number) => {
        if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return r.type_place === "Место"? 'дней' : r.type_place ==="Офис"? "месяцев" : "";
        let last_num = number % 10;
        if (last_num === 1) return r.type_place === "Место"? 'день' : r.type_place ==="Офис"? "месяц" : "";
        if ([2, 3, 4].includes(last_num)) return r.type_place === "Место"? 'дня' : r.type_place ==="Офис"? "месяца" : "";
        if ([5, 6, 7, 8, 9, 0].includes(last_num)) return r.type_place === "Место"? 'дней' : r.type_place ==="Офис"? "месяцев" : "";
    }

    const ref = useRef(null)

    useOnClickOutside(ref, () => setOpen(false), [])

    const getNameUser = () => {
        if(r.user){
            if(r.user.role.role == "User"){
                return `${r.user.lastName} ${r.user.firstName} ${r.user.patronymic}`
            }
            else if(r.user.role.role == "Company"){
                return  `${r.user.type_company} ${r.user.name_company}`
            }
        }
    }

    return (
        <div className={"adminHistoryReservation-history-item"}>
            <div className={"adminHistoryReservation-history-names"}>
                {r.type_place} №
                <span className={"color-red"}>{r.place_number}</span>
            </div>

            <div className={"adminHistoryReservation-history-tariff"}>
                {r.type_place === "Место" &&
                    <>Выбранный тариф: <span className={"color-red"}>{getTariffName(r.tariff)}</span></>
                }
                {r.type_place === "Офис" &&
                    <>Цена: <span className={"color-red"}>{r.price}</span></>
                }
            </div>

            <div className={"adminHistoryReservation-history-dates"}>
                {r.dates.length} {dayTitle(r.dates.length)}
                <div className={"adminHistoryReservation-history-dates-options"} onClick={() => setOpen(!open)}
                     ref={ref}>
                    Подробнее <img src={"/Pictures/ArrowDownRed.svg"}/>
                    <div className={"adminHistoryReservation-history-dates-options-content"} data-open={open}>
                        {r.dates.map((d, index) => {
                            return (
                                <div className={"adminHistoryReservation-history-dates-option"} key={index}>
                                    {
                                        d.start_date === d.end_date ?
                                            `${dayjs(d.start_date)
                                                .locale("ru")
                                                .format("DD MMM YYYY")}`
                                            :
                                            `${dayjs(d.start_date)
                                                .locale("ru")
                                                .format("DD MMM YYYY")} - 
                                             ${dayjs(d.end_date)
                                                .locale("ru")
                                                .format("DD MMM YYYY")}`
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className={"adminHistoryReservation-history-button"}>
                <Button onClick={() => undefined} styleProps={"white1"}>
                    {getNameUser()}
                </Button>

            </div>
        </div>
    )
};

export default AdminHistoryReservationItem;