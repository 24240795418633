import React, {useContext, useEffect, useState} from 'react';
import NavigateHeader from "../../../UI/NavigateHeader/NavigateHeader";
import {useNavigate} from "react-router-dom";
import {Context} from "../../../../index";
import Button from "../../../UI/Button/Button";
import CustomCalendar from "../../../UI/CustomCalendar/CustomCalendar";
import "./UserHistoryReservation.css"
import UserService from "../../../../services/UserService";
import {IReservationPlace} from "../../../../models/models";
import {getTariffName} from "../../../../functions/globalFunctions";
import UserHistoryReservationItem from "./UserHistoryReservationItem";
import CompanyHistoryReservationItem from "../../Company/CompanyHistoryReservation/CompanyHistoryReservationItem";

interface IFilterData {
    types?: string[],
    dates?: { start_date: string, end_date: string }[],

}

const UserHistoryReservation = () => {
    const {store} = useContext(Context)

    const [historyData, setHistoryData] = useState<IReservationPlace[]>()
    const [showFilter, setShowFilter] = useState(false)

    const [filterData, setFilterData] = useState<IFilterData>({})

    const [isFiltered, setIsFiltered] = useState(false)

    const getData = () => {
        store.DataLoadingON()
        UserService.getHistoryReservation()
            .then((r) => setHistoryData(r.data.reverse()))
            .catch(() => {alert("Что-то пошло не так")})
            .finally(() => store.DataLoadingOFF())
    }

    const getFilteredData = () => {
        store.DataLoadingON()
        UserService.getFilteredHistoryReservation(filterData)
            .then((r) => setHistoryData(r.data.reverse()))
            .catch(() => {alert("Что-то пошло не так")})
            .finally(() => store.DataLoadingOFF())
    }

    useEffect(() => {
        getData()
    }, [])

    const updateTypes = (newValue: string[]) => {
        setFilterData(prevState => ({
            ...prevState,
            types: newValue
        }));
    };

    const handleTypes = (val: string) => {
        if(filterData.types){
            if (filterData.types?.includes(val)) {
                // Если значение уже есть в массиве, убираем его
                const updatedTypes = filterData.types.filter(item => item !== val);
                updateTypes(updatedTypes);
            } else {
                const updatedTypes = [...filterData.types, val];
                updateTypes(updatedTypes);
            }
        }else
            updateTypes([val]);

    };



    return (
        <div className={"userHistoryReservation"}>
            <NavigateHeader Text={"История бронирований"} size={"medium"}/>

            <div className={"userHistoryReservation-content"}>
                <div className={"userHistoryReservation-filter"} data-show={showFilter}>
                    Тип места
                    <Button
                        selected={filterData.types?.includes("Место")}
                        onClick={() => {
                            handleTypes("Место")
                        }}
                        size={"small"} styleProps={"white1"}
                    >
                        Место
                    </Button>
                    <Button
                        selected={filterData.types?.includes("Переговорная")}
                        onClick={() => {
                            handleTypes("Переговорная")
                        }}
                        size={"small"} styleProps={"white1"} disabled={true}
                    >
                        Переговорная
                    </Button>
                    <Button
                        selected={filterData.types?.includes("Офис")}
                        onClick={() => {
                            handleTypes("Офис")
                        }}
                        size={"small"} styleProps={"white1"}
                    >
                        Офис
                    </Button>

                    Календарь
                    <CustomCalendar allDates={true} onChange={(dates) => setFilterData({...filterData, dates: dates})} multiSelect={true}/>

                    <div className={"userHistoryReservation-filter-buttons"} data-filtered={isFiltered}>
                        {isFiltered &&
                            <Button
                                onClick={() => {
                                    setIsFiltered(false)
                                    getData()
                                }}
                                styleProps={"white1"}
                                size={"small"}
                            >
                                Сбросить
                            </Button>
                        }
                        <Button
                            onClick={() => {
                                setIsFiltered(true)
                                getFilteredData()
                            }}
                            size={"small"}
                        >
                            Применить
                        </Button>
                    </div>

                    <button className={"userHistoryReservation-filter-button"}
                            onClick={() => setShowFilter(!showFilter)}>
                        <img src={showFilter? "/Pictures/crossIcon.svg" : "/Pictures/filterIcon.svg"}/>
                    </button>
                </div>
                <div className={"userHistoryReservation-history"} data-filter={showFilter}>
                    {historyData && historyData.length > 0 ?
                        historyData.map((r, index) => {
                            return <UserHistoryReservationItem r={r} key={index}/>
                        })
                        :
                        <div className={"userHistoryReservation-history-noItems"}>
                            Нет бронирований
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};

export default UserHistoryReservation;