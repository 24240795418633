import React, {useContext, useEffect, useState} from 'react';
import "./CompaniesTable.css"
import NavigateHeader from "../../../UI/NavigateHeader/NavigateHeader";
import {ConfigProvider, Table} from "antd";
import {Context} from "../../../../index";
import AdminService from "../../../../services/AdminService";
import Button from "../../../UI/Button/Button";
import CreateNewCompany from "./CreateNewCompany/CreateNewCompany";

interface ITableUsers {
    key: string,
    id: number,
    type_company: string,
    name_company: string,
    email: string,
    phone: string,
    inn: string,
    tariff: string,
    daysFree: number
}

const CompaniesTable = () => {
    const {store} = useContext(Context)
    const [dataSource, setDataSource] = useState<ITableUsers[]>()
    const [oldDataSource, setOldDataSource] = useState<ITableUsers[]>()

    const [createCompanyPopup, setCreateCompanyPopup] = useState<JSX.Element | null>(null)

    const getDataTable = () => {
        store.DataLoadingON()
        AdminService.getTableCompanies()
            .then((r) => {
                setDataSource(r.data)
                setOldDataSource(r.data)
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    const columns = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Тип компании',
            dataIndex: 'type_company',
            key: 'type_company',
        },
        {
            title: 'Название компании',
            dataIndex: 'name_company',
            key: 'name_company',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Активный тариф',
            dataIndex: 'tariff',
            key: 'tariff',
        },
        {
            title: 'Свободных дней',
            dataIndex: 'daysFree',
            key: 'daysFree',
        },
    ];

    useEffect(() => {
        getDataTable()
    }, [])

    const onSearch = (search: string) => {
        if (search?.length && search?.length > 0) {
            setDataSource(oldDataSource?.filter(i => {
                if (i.type_company.includes(search))
                    return i
                if (i.name_company.includes(search))
                    return i
                if (i.email.includes(search))
                    return i
                if (i.phone.includes(search))
                    return i
            }))
        } else {
            setDataSource(oldDataSource)
        }
    }

    return (
        <div className={"companiesTable"}>
            <NavigateHeader size={"medium"} Text={"Компании"}/>
            <div className={"companiesTable-options"}>
                <div className={"companiesTable-search"}>
                    <img src={"/Pictures/searchIcon.svg"}/>
                    <input type={"text"} placeholder={"Поиск"} onChange={(e) => onSearch(e.target.value)}/>
                </div>
                <div className={"companiesTable-addUser"}>
                    <Button onClick={() => setCreateCompanyPopup(<CreateNewCompany onCreate={() => getDataTable()}
                                                                                   onClose={() => setCreateCompanyPopup(null)}/>)}
                            type={"default"} styleProps={"red"}>
                        Добавить компанию
                    </Button>
                </div>
            </div>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            borderColor: "#AA0A22",
                            headerBg: "rgba(84,84,84,0.11)"
                        },
                    },
                    token: {

                        fontFamily: "Montserrat",
                        colorPrimary: '#AA0A22',
                        colorPrimaryActive: "#AA0A22",
                        borderRadius: 0,


                        colorBgContainer: '#FFFFFF',
                    },

                }}
            >
                <Table dataSource={dataSource} columns={columns} bordered={true}/>
            </ConfigProvider>
            {createCompanyPopup && createCompanyPopup}
        </div>
    );
};

export default CompaniesTable;