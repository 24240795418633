import React, {useState} from 'react';
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import {Calendar, ConfigProvider, DatePicker, theme} from 'antd';
import 'dayjs/locale/ru';
import "./CustomCalendar.css"

interface ICustomCalendar {
    onChange: (dates: any) => void,
    multiSelect?: boolean,
    disabledDates?: [any],
    maxDates?: number,
    userDates?: [any],
    isFixedPlace?: boolean,
    allDates?: boolean,
    isGuest?: boolean
}

const CustomCalendar = ({
                            onChange,
                            multiSelect = true,
                            disabledDates,
                            maxDates = -1,
                            userDates,
                            isFixedPlace = false,
                            allDates = false,
                            isGuest
                        }: ICustomCalendar) => {
    const [selectedDate, setSelectedDate] = useState<[]>([])

    function disabledDate(current: any) {
        if (allDates == false)
            return current.isBefore(dayjs().add(-1, 'day')) || currentMonth !== current.month()
    }

    const [currentMonth, setCurrentMonth] = useState(dayjs().month())
    const headerRender = ({value, type, onChange, onTypeChange}: any) => {

        const handleClickPreviousMonth = () => {
            onChange(value.clone().subtract(1, 'month'));
            setCurrentMonth(currentMonth === 0 ? 11 : currentMonth - 1)
        };

        const handleClickNextMonth = () => {
            onChange(value.clone().add(1, 'month'));
            setCurrentMonth(currentMonth === 11 ? 0 : currentMonth + 1)
        }

        return (
            <div className={"calendar-header"}>
                <button onClick={handleClickPreviousMonth}>{'<'}</button>
                <span>{value.locale("ru").format('MMMM YYYY')}</span>
                <button onClick={handleClickNextMonth}>{'>'}</button>
            </div>
        )
    }


    const onValueChange = (date: dayjs.Dayjs) => {
        let newDate = date.format("YYYY-MM-DD")
        if (!isFixedPlace) {
            // @ts-ignore
            if (selectedDate.includes(newDate)) {
                // @ts-ignore
                setSelectedDate([...selectedDate?.filter((d) => d !== newDate)])
                onChange([...selectedDate?.filter((d) => d !== newDate)])
            } else {
                if (multiSelect) {
                    if (maxDates !== -1 ? maxDates >= selectedDate.length + 1 : true) {
                        // @ts-ignore
                        setSelectedDate([...selectedDate, newDate])
                        onChange([...selectedDate, newDate])
                    }
                } else {
                    // @ts-ignore
                    setSelectedDate([newDate])
                    onChange([newDate])
                }
            }
        } else {
            let mas = []
            for (let i = 0; i < date.daysInMonth(); i++) {
                mas.push(date.add(i, 'day').format("YYYY-MM-DD"))
            }
            // @ts-ignore
            setSelectedDate(mas)
            onChange(mas)
        }
    };

    const dateRender = (currentDate: any, info: any) => {

        let date = currentDate.format("YYYY-MM-DD")
        let disDates = disabledDates?.map(d => {
            return dayjs(d).format("YYYY-MM-DD")
        })
        let usrDates = userDates?.map(d => {
            return dayjs(d).format("YYYY-MM-DD")
        })
        /*let start = dayjs("2024-03-27").format("YYYY-MM-DD")
        let end = dayjs("2024-03-30").format("YYYY-MM-DD")*/
        if (currentDate.month() === currentMonth) {

            if (disDates?.includes(date)) {

                return <div className="date reservation-date">
                    <div>{currentDate.date()}</div>
                </div>
            }
            if (usrDates?.includes(date)) {

                return <div className="date reservationUser-date">
                    <div>{currentDate.date()}</div>
                </div>
            }
            /*if(start <= date && date <= end){
                return <div className="date reservation-date">
                    <div>{currentDate.date()}</div>
                </div>
            }*/
            // @ts-ignore
            if (selectedDate.includes(date)) {

                return <div className="date select-date" onClick={() => {
                    isGuest ?
                        alert("Вам нужно быть авторизованным, чтобы забронировать рабочее пространство") :
                        onValueChange(currentDate)
                }}>
                    <div>{currentDate.date()}</div>
                </div>
            } else {
                return <div className="date" onClick={() => {
                    isGuest ?
                        alert("Вам нужно быть авторизованным, чтобы забронировать рабочее пространство") :
                        onValueChange(currentDate)
                }}>
                    <div>{currentDate.date()}</div>
                </div>
            }
        } else {
            return <div className={"disable-date"}></div>
        }
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Montserrat",
                    colorPrimary: '#AA0A22',
                    colorPrimaryActive: "#8F0E21",
                    borderRadius: 0,
                    colorBgContainer: '#FFFFFF',
                    colorTextDisabled: "#AA0A22",
                    fontSize: 16
                },
            }}
        >
            <Calendar
                headerRender={(e) => headerRender(e)}
                className={"interactiveMap-filter-calendar"}
                locale={locale}
                mode={"month"}
                fullscreen={false}
                disabledDate={(current) => disabledDate(current)}
                fullCellRender={(e, info) => dateRender(e, info)}
            />
        </ConfigProvider>
    );
};

export default CustomCalendar;