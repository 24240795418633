import {AxiosResponse} from "axios/index";
import $api from "../api/axios";
const {ApiRoutes: { User }} = require("../Routes/apiRoutes");
export default class UserService{
    static async getUserInfo():Promise<AxiosResponse>{
        return $api.get(User.GET_USER_INFO)
    }

    static async updateUserInfo(data: any):Promise<AxiosResponse>{
        return $api.post(User.UPDATE_USER_INFO, data)
    }

    static async changePassword(data: any):Promise<AxiosResponse>{
        return $api.post(User.CHANGE_PASSWORD, data)
    }

    static async updateAvatar(data: any):Promise<AxiosResponse>{
        return $api.post(User.UPDATE_AVATAR, data)
    }

    static async getHistoryReservation():Promise<AxiosResponse>{
        return $api.get(User.GET_HISTORY_RESERVATION)
    }

    static async getFilteredHistoryReservation(data: any):Promise<AxiosResponse>{
        return $api.post(User.GET_FILTERED_HISTORY_RESERVATION, data)
    }
}