export const SpaRoutes = {
    Home: "/",
    UNAUTHORIZED: "/unauthorized",
    MAIN: "/main",
    LOGIN: "/login",
    USER_REGISTRATION: "/userRegistration",
    COMPANY_REGISTRATION: "/companyRegistration",
    GUEST_MAP: "/coworking",

    PROFILE: "/profile",
    HISTORY: "/history",

    USER:{

    },

    COMPANY:{

    },

    ADMIN: {
        INTERACTIVE_MAP_EDIT: "/interactiveMapEdit",
        INTERACTIVE_MAP: "/interactiveMap",
        USERS: "/users",
        COMPANIES: "/companies",
    }
}