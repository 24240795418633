import React, {useContext, useEffect, useRef, useState} from 'react';
import DropDown from "../../../UI/DropDown/DropDown";
import {Calendar, ConfigProvider, Switch} from "antd";
import Button from "../../../UI/Button/Button";
import CustomCalendar from "../../../UI/CustomCalendar/CustomCalendar";
import "./ReservationPopup.css"
import NavigateHeader from "../../../UI/NavigateHeader/NavigateHeader";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import {IPlace} from "../../../../models/models";
import TariffsPopup from "../TariffsPopup/TariffsPopup";
import {Context} from "../../../../index";
import InteractiveMapService from "../../../../services/InteractiveMapService";
import {getTariffName} from "../../../../functions/globalFunctions";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import CustomCalendarMonth from "../../../UI/CustomCalendar/CustomCalendarMonth";

interface IReservationPopup {
    onClose: () => void,
    data: any,
    onUpdate: () => void
}

const ReservationOfficePopup = ({onClose, data, onUpdate}: IReservationPopup) => {
    const {store} = useContext(Context)
    const ref = useRef(null)
    useOnClickOutside(ref, () => onClose())

    const [place, setPlace] = useState(data.place)
    const [selectedDate, setSelectedDate] = useState<{ start_date: string, end_date: string }[]>()

    const onSelectMonth = (date: any[]) => {
        setSelectedDate(date.map((d) => {
            let nd = dayjs(d)
            return {
                start_date: nd.startOf("month").format("YYYY-MM-DD"),
                end_date: nd.endOf("month").format("YYYY-MM-DD")
            }
        }))
    }

    const reservationOffice = () => {
        let data = {
            place_id: place.id,
            dates: selectedDate
        }
        store.DataLoadingON()
        InteractiveMapService.reservationPlace(data)
            .then(() => {
                alert("Офис был забронирован")
                onClose()
                store.checkAuth()
                onUpdate()
            })
            .catch(() => {
                alert("Что-то пошло не так")
            })
            .finally(() => store.DataLoadingOFF())
    }

    return (
        <div className={"reservationPopup"}>
            <div className={"reservationPopup-content"} ref={ref}>
                <NavigateHeader size={"small"} Text={"Бронирование офиса"}
                                onClick={() => onClose()}/>
                <div className={"reservationPopup-place"}>
                    {place.element.type} №
                    <div>{place.number_place}</div>
                </div>

                <div className={"reservationPopup-place-price"}>
                    Цена аренды офиса на месяц: <span className={"color-red"}>{place.price}</span>
                </div>

                <div className={"reservationPopup-calendar"}>
                    Выберите месяц

                    <CustomCalendarMonth
                        multiSelect={true}
                        onSelect={(date) => onSelectMonth(date)}
                        userDates={data.reservationUser? data.reservationUser.map((r: { start_date: any; }) => r.start_date) : []}
                        disabledDates={data.reservationOther ? data.reservationOther.map((r: { start_date: any; }) => r.start_date) : []}
                    />
                </div>
                {/*<div className={"reservationPopup-result"}>
                        Итог: {}
                    </div>*/}
                <div className={"reservationPopup-buttons"}>
                    <Button onClick={() => onClose()} size={"small"} styleProps={"white1"}>
                        Отменить
                    </Button>
                    <Button onClick={reservationOffice} size={"small"}>
                        Забронировать
                    </Button>
                </div>

            </div>
        </div>
    );
};

export default ReservationOfficePopup;