import {SpaRoutes} from "../../Routes/spaRoutes";
import {IBurgerList} from "../../models/models";


export const UserBurgerList:IBurgerList[]=
    [
        {
            name: "Коворкинг",
            link: '/',
        },
        {
            name: "Профиль",
            link: SpaRoutes.PROFILE,
        },
        {
            name: "История бронирований",
            link: SpaRoutes.HISTORY,
        },
    ]