export const getTariffName = (tariff: number) => {
    switch (tariff){
        case 1:
            return "Сверхзвуковая"
        case 2:
            return "1-ая космическая"
        case 3:
            return "2-ая космическая"
        case 4:
            return "3-я космическая"
    }
}