export const ApiRoutes = {
    Authenticate: {
        LOGIN: "/Authenticate/login",
        LOGOUT: "/Authenticate/logout",
        REGISTER_USER: "/Authenticate/UserRegistration",
        REGISTER_COMPANY: "/Authenticate/CompanyRegistration",
        CHECK_AUTH: "/Authenticate/CheckAuth",
        REFRESH_TOKEN: "/Authenticate/refresh-token",
        USER_INFO: "/Authenticate/userInfo",
        //FAVORITES: "/favorites",
    },

    InteractiveMap:{
        GET_MAPS: "/InteractiveMap/GetMaps",
        GET_MAP: "/InteractiveMap/GetInteractiveMap",
        GET_FILTERED_PLACES: "/InteractiveMap/GetFilteredPlaces",
        GET_PLACE_INFO: "/InteractiveMap/GetPlaceInfo",
        RESERVATION_PLACE: "/InteractiveMap/ReservationPlace",
    },

    InteractiveMapEdit:{
        GET_MAPS: "/InteractiveMapEdit/GetMaps",
        GET_MAP: "/InteractiveMapEdit/GetInteractiveMap",

        SavePositionPlaces: "/InteractiveMapEdit/SavePositionPlaces",
        GetPlacesElements: "/InteractiveMapEdit/GetPlacesElements",
        AddUpdateElement: "/InteractiveMapEdit/AddUpdateElement",
        DeleteElement: "/InteractiveMapEdit/DeleteElement",

        GetPlaceInfo: "/InteractiveMapEdit/GetPlaceInfo",
        AddUpdatePlace: "/InteractiveMapEdit/AddUpdatePlace",
        DeletePlace: "/InteractiveMapEdit/DeletePlace",

        AddUpdateMap: "/InteractiveMapEdit/AddUpdateMap",
        DeleteMap: "/InteractiveMapEdit/DeleteMap",

    },

    Guest:{
        GET_MAPS: "/Guest/GetMaps",
        GET_MAP: "/Guest/GetInteractiveMap",
        GET_PLACE_INFO: "/Guest/GetPlaceInfo",
    },

    User: {
        GET_USER_INFO: "/User/GetUserInfo",
        UPDATE_USER_INFO: "/User/UpdateUserInfo",
        CHANGE_PASSWORD: "/User/ChangePassword",
        UPDATE_AVATAR: "/User/updateAvatar",
        GET_HISTORY_RESERVATION: "/User/GetHistoryReservation",
        GET_FILTERED_HISTORY_RESERVATION: "/User/GetFilteredHistoryReservation",
    },

    Company: {
        GET_COMPANY_INFO: "/Company/GetCompanyInfo",
        UPDATE_COMPANY_INFO: "/Company/UpdateCompanyInfo",
        CHANGE_PASSWORD: "/Company/ChangePassword",
        UPDATE_AVATAR: "/Company/updateAvatar",
        GET_HISTORY_RESERVATION: "/Company/GetHistoryReservation",
        GET_FILTERED_HISTORY_RESERVATION: "/Company/GetFilteredHistoryReservation",
    },

    Admin: {
        GET_HOME_DATA: "/Admin/GetHomeData",
        GET_PLACE_INFO: "/Admin/GetPlaceInfo",
        RESERVATION_PLACE_FOR_USER: "/Admin/ReservationPlaceForUser",
        GET_TABLE_USERS: "/Admin/GetTableUsers",
        GET_TABLE_COMPANIES: "/Admin/GetTableCompanies",
        CREATE_NEW_USER: "/Admin/CreateNewUser",
        CREATE_NEW_COMPANY: "/Admin/CreateNewCompany",
        GET_ALL_USERS_AND_COMPANIES: "/Admin/getAllUsersAndCompanies",
        GET_HISTORY_RESERVATION: "/Admin/GetHistoryReservation",
        GET_FILTERED_HISTORY_RESERVATION: "/Admin/GetFilteredHistoryReservation",
    }

}