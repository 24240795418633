import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import "./AdminMap.css"
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import DropDown, {IDropdownOption} from "../../../UI/DropDown/DropDown";

import {IElement, IHint, IInteractiveMap, IMap, IOption, IPlace, IReservationPlace} from "../../../../models/models";
import InteractiveMapService from "../../../../services/InteractiveMapService";
import {Context} from "../../../../index";
import {JSX} from "react/jsx-runtime";

import {useDate} from "../../../../hooks/useDate";
import AdminReservationPlacePopup from "./AdminReservationPopup/AdminReservationPlacePopup";
import {observer} from "mobx-react-lite";
import {getTariffName} from "../../../../functions/globalFunctions";
import ReservationOfficePopup from "./AdminReservationPopup/AdminReservationOfficePopup";
import dayjs from "dayjs";
import AdminService from "../../../../services/AdminService";
import AdminReservationOfficePopup from "./AdminReservationPopup/AdminReservationOfficePopup";

const AdminMap = () => {
    const {store} = useContext(Context)

    const [scale, setScale] = useState(100)
    const [maps, setMaps] = useState<IMap[]>([])

    const [mapSel, setMapSel] = useState<{ name: string, id: number | null }>({name: "Выберите карту", id: null})
    const [map, setMap] = useState<IMap>()
    const [interactiveMap, setInteractiveMap] = useState<IInteractiveMap>()
    const [places, setPlaces] = useState<IPlace[]>()
    const [reservationPopup, setReservationPopup] = useState<JSX.Element | null>(null)
    const refMap = useRef<HTMLDivElement | null>(null)

    const [pos, setPos] = useState({x: 0, y: 0})

    let listPlaces: JSX.Element[] = []

    // function downloadSVG() {
    //     // @ts-ignore
    //     html2canvas(document.querySelector("#MapEdit")).then(canvas => {
    //         let canvasUrl = canvas.toDataURL("image/jpeg", 0.5);
    //         console.log(canvasUrl);
    //         const createEl = document.createElement('a');
    //         createEl.href = canvasUrl;
    //         createEl.download = "download-this-canvas";
    //         createEl.click();
    //         createEl.remove();
    //     });
    // }

    const changeScale = (e: any) => {
        if (e.target.className.baseVal !== "circle") {
            var delta = e.deltaY || e.detail || e.wheelDelta;
            if (delta < 0 && scale < 200) {
                setScale(scale + 10)
            }
            if (delta > 0 && scale > 50) {
                setScale(scale - 10)
            }
        }
    }

    const getMaps = () => {
        store.DataLoadingON()
        InteractiveMapService.getMaps()
            .then((r) => {
                setMaps(r.data)
                setMapSel(r?.data[0]?.name ? {name: r.data[0].name, id: r.data[0].id} : {
                    name: "Выберите карту",
                    id: null
                })

            })
            .catch()
            .finally(() => store.DataLoadingOFF())
    }

    const getMap = () => {
        store.DataLoadingON()
        InteractiveMapService.getMap({"map_id": mapSel.id})
            .then((r) => {
                setInteractiveMap(r.data)
                setPlaces(r.data.places)
                setMap(r.data.map)
                listPlaces = []
            })
            .catch()
            .finally(() => store.DataLoadingOFF())
    }

    useEffect(() => {
        if (mapSel.name !== "Выберите карту" && mapSel.id) {
            getMap()
        }
    }, [mapSel])

    const changeMap = (e: IDropdownOption) => {
        if (e.name !== mapSel.name && e.id) {
            setMapSel({name: e.name, id: e.id})
            setScale(100)
            setPos({x: 0, y: 0})
        }
    }

    let ArrayMaps: IDropdownOption[] = []
    maps.map((item) => {
        ArrayMaps.push({id: item.id, name: item.name})
    })

    useEffect(() => {
        getMaps()
    }, [])

    const time = useDate()

    const getFilteredMap = (selectedTypes: any, selectedDates: any, selectedOptions: any) => {
        let data = {
            map_id: mapSel.id,
            selectedTypes: selectedTypes,
            selectedDates: selectedDates,
            selectedOptions: selectedOptions.filter((s: { sel: boolean; }) => s.sel).map((s: { name: any; }) => s.name)
        }
        InteractiveMapService.getFilteredPlaces(data)
            .then((r) => {
                console.log(r)
            })
    }

    const openPlace = (id: number) => {
        AdminService.getPlaceInfo({"place_id": id})
            .then((r) => {
                if (r.data) {
                    if (r.data.place.element.type === "Место")
                        setReservationPopup(<AdminReservationPlacePopup data={r.data}
                                                                        onUpdate={() => getMap()}
                                                                        onClose={() => setReservationPopup(null)}/>)
                    else if (r.data.place.element.type === "Переговорная")
                        console.log()
                    else if (r.data.place.element.type === "Офис")
                        setReservationPopup(<AdminReservationOfficePopup data={r.data}
                                                                    onUpdate={() => getMap()}
                                                                    onClose={() => setReservationPopup(null)}/>)
                }
            })
            .catch(() => alert("Что-то пошло не так"))
    }

    const today = dayjs()

    return (
        <div className={"adminInteractiveMap"}>
            <div className={"adminInteractiveMap-panel"}>
                <div className={"adminInteractiveMap-panel-info"}>
                    <div className={"adminInteractiveMap-panel-time"}>
                        <img src={"Pictures/timeIcon.svg"} alt={"time"}/>
                        {time.time}
                    </div>

                </div>
                <div className={"adminInteractiveMap-panel-map"}>
                    <DropDown
                        value={mapSel.name}
                        options={ArrayMaps}
                        onChange={(e) => changeMap(e)}
                        placeHolder={"Выберите этаж"}
                        size={"small"}/>
                </div>
                {/*<MapFilter options={adminInteractiveMap?.options} onSelect={getFilteredMap}/>*/}

            </div>
            {/*1509 903*/}
            {interactiveMap?.map &&
                <Draggable cancel={"g"} axis={"both"} scale={scale / 100} bounds={{
                    top: -interactiveMap?.map.height / 2,
                    left: -interactiveMap?.map.width / 2,
                    right: interactiveMap?.map.width / 2,
                    bottom: interactiveMap?.map.height / 2
                }}
                           position={pos}
                           onStop={(e, data) => {
                               setPos({x: data.x, y: data.y})
                           }}
                >
                    <div className={"adminInteractiveMap-content"} style={{
                        scale: `${scale}%`,
                        width: `${interactiveMap?.map.width}px`,
                        height: `${interactiveMap?.map.height}px`
                    }} ref={refMap}
                         onWheel={(e) => changeScale(e)}
                    >
                        <img src={interactiveMap?.map.image}/>

                        <svg id={"svgMap"} viewBox={`0 0 ${interactiveMap?.map.width} ${interactiveMap?.map.height}`}>
                            {places?.map((p, index) => {
                                if (p.visible) {
                                    let reservationNow: IReservationPlace | undefined = p.reservationPlace?.find((r: { dates: any[]; }) => {
                                        return r.dates.find((d) => dayjs(d.start_date).format("YYYY-MM-DD") === today.format("YYYY-MM-DD"));
                                    });
                                    return (
                                        <Draggable disabled={true} defaultPosition={{x: p.x, y: p.y}} key={p.id}>
                                            <g className={"adminInteractiveMap-place"} key={p.id} id={`place-${p.id}`}
                                               data-id={p.id}
                                               data-width={p.element.width} data-height={p.element.height}
                                               onClick={() => p.can_bron ? openPlace(p.id) : alert("Это место сейчас нельзя забронировать")}
                                            >
                                                {!p.element.only_indicator &&
                                                    <image href={p.element.image} width={p.element.width} x={0} y={0}/>}

                                                {/*<circle className={"circle"}
                                                    cx={p.element.indicator_x}
                                                    cy={p.element.indicator_y}
                                                    r={p.element.indicator_size}
                                                    fill={'url("#image'} stroke="#000000" strokeWidth="0">
                                                </circle>*/}

                                                <image
                                                    className={"adminInteractiveMap-place-indicator-image"}
                                                    data-info={
                                                        p.can_bron ?
                                                            p.user ?
                                                                "reservation"
                                                                : "free"
                                                            : "lock"
                                                    }
                                                    x={p.element.indicator_x - p.element.indicator_size}
                                                    y={p.element.indicator_y - p.element.indicator_size}
                                                    width={p.element.indicator_size * 2}
                                                    height={p.element.indicator_size * 2}
                                                    href=
                                                        {p.can_bron ?
                                                            p.user ?
                                                                p.user.avatar_url ?
                                                                    p.user.avatar_url
                                                                    : "/Pictures/userPlaceIcon.svg"
                                                                : "/Pictures/canBronIcon.svg"
                                                            : "/Pictures/lockPlaceIcon.svg"
                                                        }
                                                    clipPath={`url(#circleView${index})`}
                                                />
                                                <defs>
                                                    <clipPath id={`circleView${index}`}>
                                                        <circle cx={p.element.indicator_x} cy={p.element.indicator_y}
                                                                r={p.element.indicator_size} fill="#FFFFFF"/>
                                                    </clipPath>
                                                </defs>
                                            </g>
                                        </Draggable>
                                    )
                                }
                            })}
                        </svg>
                    </div>
                </Draggable>
            }
            {reservationPopup && reservationPopup}
        </div>
    );
};

export default observer(AdminMap);