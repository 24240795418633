import {AxiosResponse} from "axios/index";
import $api from "../api/axios";
const {ApiRoutes: { Guest }} = require("../Routes/apiRoutes");

export default class GuestService{

    static async getMaps():Promise<AxiosResponse>{
        return $api.get(Guest.GET_MAPS)
    }

    static async getMap(data:object):Promise<AxiosResponse>{
        return $api.post(Guest.GET_MAP, data)
    }

    static async getPlaceInfo(data:object):Promise<AxiosResponse>{
        return $api.post(Guest.GET_PLACE_INFO, data)
    }

}