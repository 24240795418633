import React, {useState} from 'react';
import {Calendar, ConfigProvider} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import 'dayjs/locale/ru';
import dayjs from "dayjs";


interface ICustomCalendarMonth {
    onSelect: (data: any[]) => void,
    disabledDates?: [any],
    userDates?: [any],
    multiSelect?: boolean,
    maxDates?: number,
    isGuest?: boolean
}


const CustomCalendarMonth = ({
                                 onSelect,
                                 disabledDates,
                                 userDates,
                                 multiSelect = false,
                                 maxDates = 12,
                                 isGuest
                             }: ICustomCalendarMonth) => {

    const [currentMonth, setCurrentMonth] = useState(dayjs().month())
    const [selectedDate, setSelectedDate] = useState<any[]>([])
    const select = (date: dayjs.Dayjs) => {
        let newDate = date.startOf("month").format("YYYY-MM-DD")
        // @ts-ignore
        if (selectedDate.includes(newDate)) {
            // @ts-ignore
            setSelectedDate([...selectedDate?.filter((d) => d !== newDate)])
            onSelect([...selectedDate?.filter((d) => d !== newDate)])
        } else {
            if (multiSelect) {
                if (maxDates !== -1 ? maxDates >= selectedDate.length + 1 : true) {
                    // @ts-ignore
                    setSelectedDate([...selectedDate, newDate])
                    onSelect([...selectedDate, newDate])
                }
            } else {
                // @ts-ignore
                setSelectedDate([newDate])
                onSelect([newDate])
            }
        }

    }

    function disabledDate(current: any) {
        return current.isBefore(dayjs().add(1, 'month'))
    }

    const dateRender = (currentDate: dayjs.Dayjs) => {
        let dateText = dayjs(currentDate).locale("ru").format("MMM")
        let date = currentDate.startOf("month").format("YYYY-MM-DD")

        let disDates = disabledDates?.map(d => {
            return dayjs(d).format("YYYY-MM-DD")
        })
        let usrDates = userDates?.map(d => {
            return dayjs(d).format("YYYY-MM-DD")
        })
        console.log(disabledDates)
        if (disDates?.includes(date)) {

            return <div className="date-month reservation-month">
                <div>{dateText}</div>
            </div>
        }
        if (usrDates?.includes(date)) {

            return <div className="date-month reservationUser-month">
                <div>{dateText}</div>
            </div>
        }
        /*if(start <= date && date <= end){
            return <div className="date reservation-date">
                <div>{currentDate.date()}</div>
            </div>
        }*/
        // @ts-ignore
        if (selectedDate.includes(date)) {

            return <div className="date-month select-date-month" onClick={() => {
                isGuest?
                    alert("Вам нужно быть авторизованным, чтобы забронировать рабочее пространство") :
                    select(currentDate)
            }}>
                <div>{dateText}</div>
            </div>
        } else {
            return <div className="date-month" onClick={() => {
                isGuest?
                    alert("Вам нужно быть авторизованным, чтобы забронировать рабочее пространство") :
                    select(currentDate)
            }}>
                <div>{dateText}</div>
            </div>
        }

    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "Montserrat",
                    colorPrimary: '#AA0A22',
                    colorPrimaryActive: "#8F0E21",
                    borderRadius: 0,
                    colorBgContainer: '#FFFFFF',
                    colorTextDisabled: "#AA0A22",
                    fontSize: 16
                },
            }}
        >
            <Calendar

                className={"customCalendarMonth"}
                locale={locale}
                mode={"year"}
                fullscreen={false}
                disabledDate={disabledDate}
                monthFullCellRender={dateRender}
            />
        </ConfigProvider>
    );
};

export default CustomCalendarMonth;