import React, {useContext} from "react";
import {Context} from "../../index";
import GuestHome from "../Home/GuestHome/GuestHome";
import Map from "./Map/Map";
import AdminHome from "../Home/AdminHome/AdminHome";
import UserProfile from "./User/UserProfile/UserProfile";
import CompanyProfile from "./Company/CompanyProfile/CompanyProfile";
import UserHistoryReservation from "./User/UserHistoryReservation/UserHistoryReservation";
import CompanyHistoryReservation from "./Company/CompanyHistoryReservation/CompanyHistoryReservation";
import AdminHistoryReservation from "./Admin/AdminHistoryReservation/AdminHistoryReservation";

export const Home = () => {
    const {store} = useContext(Context)
    return (
        <>
            {(store.role === 'Guest' || !store.isAuth) &&  <GuestHome/>}
            {(store.role === 'User' && (store.isAuth)) && <Map/>}
            {(store.role === 'Company' && (store.isAuth)) && <Map/>}
            {(store.role === 'Admin' && (store.isAuth)) && <AdminHome/>}
        </>
    );
};

export const Profile = () => {
    const {store} = useContext(Context)
    return (
        <>
            {(store.role === 'User' && (store.isAuth)) && <UserProfile/>}
            {(store.role === 'Company' && (store.isAuth)) && <CompanyProfile/>}
        </>
    );
};

export const HistoryReservation = () => {
    const {store} = useContext(Context)
    return (
        <>
            {(store.role === 'User' && (store.isAuth)) && <UserHistoryReservation/>}
            {(store.role === 'Company' && (store.isAuth)) && <CompanyHistoryReservation/>}
            {(store.role === 'Admin' && (store.isAuth)) && <AdminHistoryReservation/>}
        </>
    );
};