import React, {useContext, useEffect, useState} from 'react';
import Draggable from "react-draggable";
import Input from "../../UI/Input/Input";
import {IAdminHomeData} from "../../../models/models";
import AdminService from "../../../services/AdminService";
import "./AdminHome.css"
import {Context} from "../../../index";

const AdminHome = () => {
    const {store} = useContext(Context)
    const [homeData, setHomeData] = useState<IAdminHomeData>()

    const getHomeData = () => {
        store.DataLoadingON()
        AdminService.getHomeData()
            .then(r => setHomeData(r.data))
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    useEffect(() => {
        getHomeData()
    },[])

    return (
        <div className={"adminHome"}>
            <div className={"adminHome-header"}>Панель администратора</div>
            <div className={"adminHome-content"}>
                <div className={"adminHome-item"}>
                    <div>Пользователи</div>
                    <div>{homeData?.countUsers}</div>
                </div>
                <div className={"adminHome-item"}>
                    <div>Компании</div>
                    <div>{homeData?.countCompanies}</div>
                </div>
                <div className={"adminHome-item"}>
                    <div>Сейчас в коворкинге</div>
                    <div>{homeData?.countActiveUsers}</div>
                </div>
            </div>
        </div>
    );
};

export default AdminHome;